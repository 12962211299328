import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from 'src/Utils/axios';
import { enqueueSnackbar } from "notistack";

import { dispatch } from "src/Redux/store";



const initialState= {
    isLoading: false,
    error: null,
    addTagUser: {},
    getTagsUserData: [],
    deleteTagResponse: {},
    getTagsLoading: false,
    tagDialog: false,
    updateTagResponse: [],
}

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        
        // GET USER DATA SSUCCESS
        tagsPatchSuccess(state, action) {
          state.addTagUser = action.payload;
          state.isLoading = false;
        },

        tagsGetSuccess(state, action) {
            state.getTagsUserData = action.payload;
            state.getTagsLoading = false;
          },

          tagsDeleteSuccess(state, action) {
            state.deleteTagResponse = action.payload;
            state.isLoading = false;
          },

          setTagsLoading(state, action){
            state.getTagsLoading = action.payload
          },

          setTagsDialog(state, action){
            state.tagDialog = action.payload
          },

          setUpdateTagResponse(state, action){
            state.updateTagResponse = action.payload
            state.isLoading = false;
          }

      
       

    }
})

export const getAllTags = (state) => state.tags;

export default tagsSlice.reducer

export const {
  setTagsDialog
} = tagsSlice.actions

// Actions





  export function getTagsUser() {
    return async () => {
      dispatch(tagsSlice.actions.setTagsLoading(true));
      try {
        const response = await axios.get(`user/me?fields=Tags`);
          dispatch(tagsSlice.actions.tagsGetSuccess(response.data.data.Tags));
          dispatch(tagsSlice.actions.setTagsLoading(false));
      } catch (error) {
        dispatch(tagsSlice.actions.hasError(error));
        dispatch(tagsSlice.actions.setTagsLoading(false));
      }
    };
  }




   export function patchTagsUser(data) {
    return async () => {
      dispatch(tagsSlice.actions.startLoading());
      try {
        const response = await axios.patch(`user`, data);
          dispatch(tagsSlice.actions.tagsPatchSuccess(response.data));
          enqueueSnackbar(`Tag is Created`, {
            autoHideDuration: 3000
          });
          dispatch(getTagsUser())
      } catch (error) {
        dispatch(tagsSlice.actions.hasError(error));
        enqueueSnackbar(`Tags Creation Failed`, {
          autoHideDuration: 3000
        });
      }
    };
  } 

  export function deleteTagUser(data) {
    return async () => {
      dispatch(tagsSlice.actions.startLoading());
      try {
        const response = await axios.patch(`user`, data);
          dispatch(tagsSlice.actions.tagsPatchSuccess(response.data));
          enqueueSnackbar(`Tag is Deleted`, {
            autoHideDuration: 3000
          });
          dispatch(getTagsUser())
      } catch (error) {
        dispatch(tagsSlice.actions.hasError(error));
        enqueueSnackbar(`Tags Deleted Failed`, {
          autoHideDuration: 3000
        });
      }
    };
  } 
  



  export function updateTag(id, data) {


    return async () => {
      dispatch(tagsSlice.actions.startLoading());
      try {
        const response = await axios.patch(`/contact/${id}`, data);
        dispatch(tagsSlice.actions.setUpdateTagResponse(response.data));
        if (response.data.status === true) {
          if (data?.Notes){
            enqueueSnackbar('Notes Update Success', {
              autoHideDuration: 3000
            });
          }
          else{
            enqueueSnackbar('Tags Update Success', {
              autoHideDuration: 3000
            });
          }
        } else {
          enqueueSnackbar('Oops.. Notes Update Failed!', {
              autoHideDuration: 3000
            });
        }
      } catch (error) {
        dispatch(tagsSlice.actions.hasError(error));
        enqueueSnackbar('Oops.. Notes Update Failed!', {
          autoHideDuration: 3000
        });
      }
    };
  }