import { createSlice } from "@reduxjs/toolkit";
import axios from '../../../Utils/axios';
import { dispatch } from "../../store";
import { enqueueSnackbar } from "notistack";

const initialState= {
    allInboxLoading:  false,
    inboxDataLoading: false,
    inboxAllList: [],
    isLoading: false,
    isLoadingFilter: false,
    isLoadingExport: false,
    isLoadingNotes: false,
    isStartLoadingStatus: false,
    error: null,
    inboxVoiceData: {},
    inboxVoiceSearchData: {},
    inboxVoiceFilterData: {},
    contactData: {},
    addNotesResponse: {},
    updateNotesResponse: {},
    deleteNotes: {},
    updateStatusResponse: {},
    exportDataResponse: {},
    updateInboxColumn: {},
    updateInboxDragColumn: {},
    inboxtrailData: {},
    isRefresh: false,
    statusUpdateResponse: {},
    getFlowByIdResponse: {},
    filterShowExport: false,

    callDetailsDrawer: false,
    voiceId: '',
    conversationIdResponse: [],
    stats: [],

    filterApplied: false,
    filterQuery: [],
    filterDrawer: false,
    filterFlag: false,
    filterExportLoading: false,
    filterExportData: [],
}

const InboxSlice = createSlice({
    name: 'Voice',
    initialState,
    reducers: {
        setAllInboxLoading(state, action){
            state.allInboxLoading = action.payload
        },
        setInboxAllList(state, action){
            state.inboxAllList = action.payload
        },
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // START LOADING
        startLoadingStatus(state) {
            state.isStartLoadingStatus = true;
        },
    
        // START LOADING
        stopLoadingStatus(state) {
            state.isStartLoadingStatus = false;
        },
    
        setFilterApplied(state, action) {
          state.filterApplied = action.payload;
        },
        setFilterQuery(state, action) {
          state.filterQuery = action.payload;
        },
        setFilterDrawer(state, action) {
          state.filterDrawer = action.payload;
        },

        setFilterFlag(state, action) {
          state.filterFlag = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setStats(state, action){
          state.stats = action.payload
        },
        getInBoxDataLoading(state, action){
          state.inboxDataLoading = action.payload
        },
        // GET IVR VOICE
        getIvrSuccess(state, action) {
            state.inboxVoiceData = action.payload;
            state.inboxDataLoading = false;
        },
        // GET CONTACT USING NUMBER
        getContactSuccess(state, action) {
            state.contactData = action.payload;
            state.isLoading = false;
        },
        // PUT STATUS
        updateStatusSuccess(state, action) {
            state.updateStatusResponse = action.payload;
            state.isLoading = false;
        },
        putAddStatusResponse(state, action) {
            state.statusUpdateResponse = action.payload
            state.isLoading = false
        },
        setCallDetailsDrawer(state, action) {
            state.callDetailsDrawer = action.payload
        },
        setVoiceId(state, action) {
            state.voiceId = action.payload
        },
        setConversationIdSuccess(state, action){
            state.conversationIdResponse = action.payload
            state.isLoading = false
          },
          setFilterExportLoading(state, action){
            state.filterExportLoading = action.payload
          },
          setFilterExportData(state, action){
            state.filterExportData = action.payload
          },

        
    }
})

export default InboxSlice.reducer

export const {
    setInboxAllList,
    setCallDetailsDrawer,
    setVoiceId,
    setFilterApplied,
    setFilterQuery,
    setFilterDrawer,
    setFilterFlag,
    setFilterExportLoading,
    setFilterExportData
} = InboxSlice.actions


export function getAllInbox() {
    return async () => {
      dispatch(InboxSlice.actions.setAllInboxLoading(true));
      try {
        const response = await axios.get(`/inbox/view`);
        dispatch(InboxSlice.actions.setInboxAllList(response.data.data))
        dispatch(InboxSlice.actions.setAllInboxLoading(false));
      } catch (error) {
        dispatch(InboxSlice.actions.setAllInboxLoading(false));
      }
    };

  }


  

  export function getConversationId(id) {

    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      
      try {
        const response = await axios.get(`/voice/${id}`);
        dispatch(InboxSlice.actions.setConversationIdSuccess(response.data.data));
        console.log('all conversation response', response)

      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
      }
    };

  }
  export function getAllStats(data) {

    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      
      try {
        const response = await axios.post(`/stats/voice`, {CloudNumber: [data.CloudNumber]});
        // const response = await axios.get(`/stats/voice?CloudNumber=${data.CloudNumber}`);
        dispatch(InboxSlice.actions.setStats(response.data));
        console.log('getStats', response)

      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
      }
    };
  }

  

  export function getIvrVoice(number, offset, limit) {
    return async () => {
      dispatch(InboxSlice.actions.getInBoxDataLoading(true));
      try {
        const response = await axios.get(`/voice?CloudNumber=${number}&offset=${offset}&limit=${limit}&sort=-_id&populate=contactId`);
        dispatch(InboxSlice.actions.getIvrSuccess(response.data.data));
        console.log('getIvrVoice', response)
      } catch (error) {
        dispatch(InboxSlice.actions.getInBoxDataLoading(false));
      }
    };
  }

  export function getVoiceFilter(data) {
    return async () => {
      dispatch(InboxSlice.actions.getInBoxDataLoading(true));
      try {
        data.populate = 'contactId';
        const response = await axios.post(`/filter/voice`, data);
        dispatch(InboxSlice.actions.getIvrSuccess(response.data.data));
        console.log('getIvrVoice', response)
      } catch (error) {
        dispatch(InboxSlice.actions.getInBoxDataLoading(false));
      }
    };
  }

  export function getVoiceFilterExport(data) {
    return async () => {
      dispatch(InboxSlice.actions.setFilterExportLoading(true));
      dispatch(InboxSlice.actions.getInBoxDataLoading(true));
      try {
        data.populate = 'contactId';
        const response = await axios.post(`/filter/voice`, data);
        dispatch(InboxSlice.actions.setFilterExportData(response.data.data));
        console.log('getVoiceFilterExport', response.data.data)
        dispatch(InboxSlice.actions.setFilterExportLoading(false));
        dispatch(InboxSlice.actions.getInBoxDataLoading(false));
      } catch (error) {
        dispatch(InboxSlice.actions.setFilterExportLoading(false));
        dispatch(InboxSlice.actions.getInBoxDataLoading(true));
      }
    };
  }



  export function updateColSettings(id, dragData, showData) {
    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      try {
        const response = await axios.put(`v2/user/${id}`, { "ivrColumnSettings": dragData, "ivrColumn": showData });
          enqueueSnackbar(`Column setting Updated`, {
            autoHideDuration: 3000
          });
      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
        enqueueSnackbar(`Column setting Update Failed`, {
          autoHideDuration: 3000
        });
      }
    };
  }























  
  export function getContactDetails(number) {
    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      try {
        const response = await axios.get(`/contacts?phoneNumber=${number}`);
        dispatch(InboxSlice.actions.getContactSuccess(response.data));
        console.log('getContactDetails', response)
      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
  
      }
    };
  }
  
  
  export function updateStatus(id, data) {
  
    return async () => {
      dispatch(InboxSlice.actions.startLoadingStatus());
      try {
        const response = await axios.put(`voice/${id}`, { "Status": data });
        dispatch(InboxSlice.actions.updateStatusSuccess(response.data));
        console.log('update status redux', response)
        dispatch(InboxSlice.actions.stopLoadingStatus());
        if (response.data.status === true) {
         enqueueSnackbar('Status Changed Successfully')
        } else {
         enqueueSnackbar('Oops.. Trouble while updating Status')
        }
  
      } catch (error) {
        dispatch(InboxSlice.actions.stopLoadingStatus());
        dispatch(InboxSlice.actions.hasError(error));
       enqueueSnackbar('Oops.. Trouble while updating Status')
      }
    };
  }
  

  

  
  
  
  export function addStatus(id, data) {
    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      try {
        const response = await axios.put(`/users/${id}`, { "inboxVoiceStatus": data });
        dispatch(InboxSlice.actions.putAddStatusResponse(response.data));
        if (response.data.status === true) {
         enqueueSnackbar('Status Added')
  
        } else {
         enqueueSnackbar('Oops.. Trouble to Save Change')
        }
      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
       enqueueSnackbar('Oops.. Trouble to Save Change')
      }
    };
  }
  
  export function updateUserStatus(id, data) {
    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      try {
        const response = await axios.put(`/users/${id}`, { "inboxVoiceStatus": data });
        dispatch(InboxSlice.actions.putAddStatusResponse(response.data));
        if (response.data.status === true) {
         enqueueSnackbar('Status Updated')
  
        } else {
         enqueueSnackbar('Oops.. Trouble to Save Change')
        }
      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
       enqueueSnackbar('Oops.. Trouble to Save Change')
      }
    };
  }
  
  export function deleteUserStatus(name) {
    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      try {
        const response = await axios.put(`/users/deleteStatus/${name}`);
        dispatch(InboxSlice.actions.putAddStatusResponse(response.data));
        // if (response.data.status === true) {
        //  enqueueSnackbar('Status Deleted'));
        // } else {
        //  enqueueSnackbar('Oops.. Trouble to Save Change'));
        // }
      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
       enqueueSnackbar('Oops.. Trouble to Save Change')
      }
    };
  }
  
  export function deleteUserStatusList(id, data) {
    return async () => {
      dispatch(InboxSlice.actions.startLoading());
      try {
        const response = await axios.put(`/users/${id}`, { "inboxVoiceStatus": data });
        dispatch(InboxSlice.actions.putAddStatusResponse(response.data));
        if (response.data.status === true) {
         enqueueSnackbar('Status Deleted')
        } else {
         enqueueSnackbar('Oops.. Trouble to Delete')
        }
      } catch (error) {
        dispatch(InboxSlice.actions.hasError(error));
       enqueueSnackbar('Oops.. Trouble to Delete')
      }
    };
  }
  
  
  
  