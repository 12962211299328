
import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from 'src/Utils/axios';

import { dispatch } from "src/Redux/store";

import { enqueueSnackbar } from "notistack";

const authID = localStorage.getItem('authID')


const initialState= {
    isLoading: false,
    error: null,
    getPowerDialersData: {},
    deletePowerDialerResponse: {},
    postManyPowerDialer: {},
}

const powerDialerSlice = createSlice({
    name: 'powerDialer',
    initialState,

    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        
        getPowerDialerDataSuccess(state, action) {
            state.isLoading = false;
            state.getPowerDialersData = action.payload;
        },

        // START LOADING
        deletePowerDialerSuccess(state, action) {
            state.isLoading = false;
            state.deletePowerDialerResponse = action.payload
        },

        // START LOADING
        postManyPowerDialerSuccess(state, action) {
          state.isLoading = false;
          state.postManyPowerDialer = action.payload
      },



       
    }
})



export const selectAllPowerDialerData = (state) => state.powerDialer;

export default powerDialerSlice.reducer



export function getAllPowerDialers(offsetCount, rowsPerPage) {
    return async () => {
      dispatch(powerDialerSlice.actions.startLoading());
      try {
        const response = await axios.get(`power_dialer?populate=contactId&populate=userId&authId=${authID}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
          dispatch(powerDialerSlice.actions.getPowerDialerDataSuccess(response.data));
 
      } catch (error) {
        dispatch(powerDialerSlice.actions.hasError(error));
      }
    };
  }

  export function deletePowerDialer(id) {
    return async () => {
      dispatch(powerDialerSlice.actions.startLoading());
      try {
        const response = await axios.delete(`/power_dialer/${id}`);
          dispatch(powerDialerSlice.actions.deletePowerDialerSuccess(response.data));
          if(response.data.status === true){
            enqueueSnackbar(`Hurray.. Successfully Deleted`, {
              autoHideDuration: 3000
            });
            dispatch(getAllPowerDialers(0,10))
          }else{
            enqueueSnackbar(`Oops.. Delete Failed`, {
              autoHideDuration: 3000
            });
          }
      } catch (error) {
        enqueueSnackbar(`Oops.. Delete Failed`, {
          autoHideDuration: 3000
        });
          dispatch(powerDialerSlice.actions.hasError(error));
      }
    };
  }



  export function manyPowerDialer(data) {
    enqueueSnackbar(`Power Dialer Adding on Progress`, {
      autoHideDuration: 3000
    });
    return async () => {
      dispatch(powerDialerSlice.actions.startLoading());
      try {
        const response = await axios.post(`/v2/power_dialer/insert`, data);
          dispatch(powerDialerSlice.actions.postManyPowerDialerSuccess(response.data));
          if(response.data.status === true){
            enqueueSnackbar(`Hurray.. Successfully Added`, {
              autoHideDuration: 3000
            });
          }else{
            enqueueSnackbar(`Oops.. Adding Failed`, {
              autoHideDuration: 3000
            });
          }
      } catch (error) {
          dispatch(powerDialerSlice.actions.hasError(error));
          enqueueSnackbar(`Oops.. Adding Failed`, {
            autoHideDuration: 3000
          });
      }
    };
  }