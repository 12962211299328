import { createSlice } from "@reduxjs/toolkit";
import axios from '../../../Utils/axios';
import { dispatch } from "../../store";
import { enqueueSnackbar } from "notistack";

const initialState= {
    smsLoading:  false,
    smsConversationList: [],
    smsSendLoading: false,
    smsSendData: [],
    smsSendResponse: [],
    smsConversationId: '',
    activeConversation: [],
    activeConversationName: '',
    activeConversationPhone: '',
    activeSendMessages: [],
    contactsDrawer: false,
    seletectedContacts: [],
    newChatLeft: [],
    searchConversationResponse: [],
    sideConversations: [],
    smsUseId : '',
    smsChatOffset: 0,
    smsMessagesLoading: false,
    smsMessages: [],
}

const SmsSlice = createSlice({
    name: 'Sms',
    initialState,
    reducers: {
        setSmsLoading(state, action){
            state.smsLoading = action.payload
        },
        setSmsConversationList(state, action){
            state.smsConversationList = action.payload
        },
        setSmsSendLoading(state, action){
            state.smsSendLoading = action.payload
        },
        setSmsSendResponse(state, action){
            state.smsSendResponse = action.payload
        },
        setSmsConversationId(state, action){
            state.smsConversationId = action.payload
        },
        setActiveConversation(state, action){
            state.activeConversation = action.payload
        },
        setActiveConversationName(state, action){
          state.activeConversationName = action.payload
          },
          setActiveConversationPhone(state, action){
            state.activeConversationPhone = action.payload
        },
        setActiveSendMessages(state, action){
            state.activeSendMessages = action.payload
        },
        setContactsDrawer(state, action){
              state.contactsDrawer = action.payload
        },
        setSeletectedContacts(state, action){
          state.seletectedContacts = action.payload
        },
        setNewChatLeft(state, action){
          state.newChatLeft = action.payload
        },
        setSearchConversationResponse(state, action){
          state.searchConversationResponse = action.payload
        },
        setSideConversations(state, action){
          state.sideConversations = action.payload
        },
        setSmsUseId(state, action){
          state.smsUseId = action.payload
        },
        setSmsSendData(state, action){
          state.smsSendData = action.payload
        },
        setSmsChatOffset(state, action){
          state.smsChatOffset = action.payload
        },
        setSmsMessagesLoading(state, action){
          state.smsMessagesLoading = action.payload
        },
        setSmsMessages(state, action){
          state.smsMessages = action.payload
        }


    }
})

export default SmsSlice.reducer

export const {
    setSmsLoading,
    setSmsConversationList,
    setSmsConversationId,
    setActiveConversation,
    setActiveConversationName,
    setActiveConversationPhone,
    setActiveSendMessages,
    setContactsDrawer,
    setSeletectedContacts,
    setNewChatLeft,
    setSearchConversationResponse,
    setSideConversations,
    setSmsUseId,
    setSmsSendData,
    setSmsChatOffset,
    setSmsMessagesLoading,
    setSmsMessages
} = SmsSlice.actions


export function getAllConversations(number, offset, limit) {
    return async () => {
      dispatch(SmsSlice.actions.setSmsLoading(true));
      try {
        const response = await axios.get(`/sms/conversations?populate=lastMessageId&populate=contactId&CloudNumber=${number}&offet=${offset}&limit=${limit}`);
        dispatch(SmsSlice.actions.setSmsConversationList(response.data.data))
        dispatch(SmsSlice.actions.setSmsLoading(false));
      } catch (error) {
        dispatch(SmsSlice.actions.setSmsLoading(false));
      }
    };

  }


  export function sendSms(data) {
    return async () => {
      dispatch(SmsSlice.actions.setSmsSendLoading(true));
      try {
        const response = await axios.post(`/sms/thinq/send`, data);
        
        console.log('redux send sms', response)
        if(response.data.status === true){
            dispatch(SmsSlice.actions.setSmsSendResponse(response.data))
            dispatch(SmsSlice.actions.setSmsConversationId(response.data.data.conversationId))
        }else{
            enqueueSnackbar(response.data.message, {
              variant: 'error'
            })
        }
        
        dispatch(SmsSlice.actions.setSmsSendLoading(false));
      } catch (error) {
        dispatch(SmsSlice.actions.setSmsSendLoading(false));
        enqueueSnackbar('Message send Failed', {
          variant: 'error'
        })
      }
    };

  }



  export function smsConversationSearchByName(name) {
    return async () => {
      dispatch(SmsSlice.actions.setSmsLoading());
      try {
        const response = await axios.get(`filter/sms/conversations?populate=lastMessageId&name=${name}&populate=contactId`);
        dispatch(SmsSlice.actions.setSearchConversationResponse(response.data.data));
        dispatch(SmsSlice.actions.setSmsLoading(false));
      } catch (error) {
        dispatch(SmsSlice.actions.setSmsLoading(false));
      }
    };
  }


  export function smsConversationSearchByPhone(number) {
    return async () => {
      dispatch(SmsSlice.actions.setSmsLoading());
      try {
        const response = await axios.get(`filter/sms/conversations?populate=lastMessageId&number=${number}&populate=contactId`);
        dispatch(SmsSlice.actions.setSearchConversationResponse(response.data.data));
        dispatch(SmsSlice.actions.setSmsLoading(false));
      } catch (error) {
        dispatch(SmsSlice.actions.setSmsLoading(false));
      }
    };
  }



  export function getMessages(smsConversationId) {
    return async () => {
      dispatch(setSmsMessagesLoading(true));
      try {
        const response = await axios.get(`/sms/messages/${smsConversationId}?sort=-createdAt&offset=0&limit=20`);
        dispatch(setSmsMessages(response.data.data))
        dispatch(setSmsMessagesLoading(false));
      } catch (error) {
        dispatch(setSmsMessagesLoading(false));
      }
    };

  }


