
import { styled } from '@mui/material/styles';
import vibtreeIcon from 'src/assets/vib-icon-dark.svg'
import { Box, LinearProgress, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({message}) {
  return (
    <>
        <RootStyle>
          <Box className="full-loading-card">
              <Typography style={{textAlign: 'center'}}>
                <img
                    src={vibtreeIcon}
                    style={{width: '40px', marginLeft: 'auto', marginRight: 'auto'}}
                />
              </Typography>
              <Typography>
                {message}
                <LinearProgress />
              </Typography>
            </Box>
        </RootStyle>

    </>
  );
}
