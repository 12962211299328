import React, {useRef, useEffect, useMemo, useState } from 'react'
import './App.css';
import Router from './Routes'
import {socket, socketIncoming} from 'src/Utils/sockets'
import { useNavigate,useLocation } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google';
import {clientId} from '../src/config/index';
// import ThemeProvider from 'src/components/theme/index';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import NotistackProvider from 'src/components/NotistackProvider';
import {
  Snackbar,
  Alert
} from '@mui/material'

import UnderMaintanance from 'src/components/UnderMaintainence';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { dispatch, useSelector } from './Redux/store';
import { setOnlineOffline } from './Redux/Slices/Layout/Layout';

import typography from 'src/components/theme/typography';
import palette from 'src/components/theme/palette';
import shadows, { customShadows } from 'src/components/theme/shadows';
import componentsOverride from 'src/components/theme/overrides';

function App() {

  const isLight = true;

  const navigate = useNavigate()
  const location = useLocation()

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      // breakpoints,
      shape: { borderRadius: 8 },
      // direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    []
  );

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  const { onlineOffline } = useSelector((state)=> state.Layout)

  const [openOnline, setOpenOnline] = useState(false)
  const [openOffline, setOpenOffline] = useState(false)

  function getCookie(uid) {
    let vibUid = uid + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(vibUid) === 0) {
        return c.substring(vibUid.length, c.length);
      }
    }
    return "";
  }

  function checkCookie() {
    let vibUId = getCookie("vib_u_id");
    if (vibUId !== "" && vibUId != null) {
    } else {
      if(location.pathname.includes('/auth/')){
        
      }
      else{
        navigate('/')
      }
    }
  }

  useEffect(()=>{
    checkCookie()
  },[])

  useEffect(()=>{

    console.log("Socket state effect", onlineOffline);
     // Connect event
     socket.on("connect", () => {
      console.log("Socket connected 1");
      if (socket.recovered) {
        // any missed packets will be received
        console.log("Socket connected 2");
      } else {
        // new or unrecoverable session
        console.log("Socket connected 3");
      }
      // Send a ping event to the server
      socket.emit("ping");
    });

    // Pong event
    socket.on("pong", () => {
      console.log("Socket Received pong from server");
      // Perform actions or handle responses related to the pong event
    });

    socket.on('disconnect', function(reason){
        console.log('User 1 disconnected because '+reason);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };


  },[])



  window.addEventListener('offline', function () {
    setOpenOnline(false)
    setOpenOffline(true)
  });
  
  // Listen for the 'online' event
  window.addEventListener('online', function () {
    setOpenOnline(true)
    setOpenOffline(false)
    dispatch(setOnlineOffline(!onlineOffline))
    setTimeout(() => {
      setOpenOnline(false)
    }, 3000);
  });




  return (
    <>

    <GoogleOAuthProvider clientId={clientId}>
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
      {/* <ThemeProvider> */}
        <NotistackProvider>
          <Router />
          <UnderMaintanance />

          <Snackbar open={openOffline} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
            <Alert severity="error" sx={{ width: '100%' }}>
              Internet connection lost!
            </Alert>
          </Snackbar>

          <Snackbar open={openOnline} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
            <Alert severity="success" sx={{ width: '100%' }}>
              Internet connection back!
            </Alert>
          </Snackbar>

        </NotistackProvider>
      {/* </ThemeProvider> */}
        </MUIThemeProvider>
      </StyledEngineProvider>
      </GoogleOAuthProvider>
      

    </>
  );
}

export default App;
