
import { createSlice } from "@reduxjs/toolkit";
import { enqueueSnackbar } from 'notistack'
// utils
import axios from './../../../Utils/axios';

import { dispatch } from "../../store";



const initialState= {
    isLoading: false,
    isLoadingAllowedCountry: false,
    isLoadingCloudNumbers: false,
    isLoadingContactCheck: false,
    error: null,


    // variables
    toggleWidget: false, // false
    showDialPad: false,
    showConversation: false, // false
    showPowerDialer: false,
    showSettings: false,
    showNav: false,
    selectedCountry: '',
    selectedPhoneNumber: '',
    allowedCountry: [],
    showCallFrom: false,
    showCallUsing: false,
    selectedCallUsing: 'Phone',
    cloudNumbers: [],
    selectedCloudNumberCountry: '',
    selectedCloudNumber: '',
    showCalling: false, // false
    triggerCall: false,
    showDialing: false, // false
    checkPhoneNumber: [],
    selectedCustomerName: '',
    selectedCustomerId: '',
    createContactResponse: [],
    isMakingCall: false,
    makeCallResponse: [],
    callingCallSid: '',
    isKillingCallLoading: false,
    killCallResponse: [],

    // call statss
    showConnected: false, // false
    isRinging: false,
    isDialing: false,
    isFailed: false,
    isBusy: false,
    isConnected: false, // false
    isDisconnected: false,
    isCanceled: false,
    isNoAnswer: false,
    // notes
    showNotes: false,
    showTags: false,
    showAssign: false,
    callNotes: '',
    tagsResponse: [],
    selectedTags: [],
    selectedAssignUser: '',

    // kill
    isKillLoading: false,
    killConferenceResponse: [],
    friendlyName: '',

    // notes
    addCallNotesResponse: [],
    killTriggered: false, // kill trigger to ignore socket response
    assignUsersResponse: [],

    // app data
    appConfigResponse: [],

    //conversation
    isLoadingConversation: true,
    conversationAllResponse: [],
    conversationMissedResponse: [],
    refreshConversation: false,
    triggerClickToCall: false,
    notesDrawer: false,
    selectedNotesId: '',
    notesIdResponse: [],
    conversationDetailsDrawer: false,
    conversationIdResponse: false,
    // incoming Call
    incomingCallTriggered: false,
    // Sip Call or Browser Call
    triggerBrowserCall: false,
    incomingBrowserCall: false,
    incomingBrowserNumber: '',
    // power Dialer
    poweDialerCallIndex: 0,
    powerDialerCallTriggered: false,
    sipRegistered: false,
    numberOfWebrtcConnection: 0,

    validateCallFrom: false,
    powerDialerListLoading: false,
    powerDialerListResponse: [],
    powerDialerFilterStatus: 'All',
    powerDialerTotalCount: 0,
    callResponseStatus: '',

    // settings
    showCurrentStatus: false,
    showTeam: false,
    showBlacklist: false,
    showCallingPattern: false,
    currentStatus: '',
    statusResponse: [],
    blackListResponse: [],
    userDetails: '',

    // transfer
    showTransferType: false,
    showTransferTeam: false,
    showTransferOther: false,
    showTransferOptions: true,
    selectedTransferAgent: '',
    selectedTransferContact: '',
    selectedTransferAgentNumber: '',
    selectectedTranferContactNumber: '',
    isTransferActivate: false,
    realtimeData: [],
    realtimeLoading: false,
    transferLoading: false,
    existingCallData: [],
    existingCallDataOfIncomingTransfer: {}, // binding the incoming coming from tranfer
    existingCallDataOfIncoming: {}, // binding the incoming here not coming from transfer
    realLimeLiveCallConferenceData: [],
    isIncoming: false,
    transferDone: false,
    transferShowAlert: false,
    transferAlertMessage: '',
    transferStatus: '',
    transferUser: '',
    transferInitialLoading: false,
    

    // on call
    onCall: false,
    activeCall: [],
    phoneHold:false,
    customerSidOutbound: '',
    activeConferenceId : '',
    fromCalledRealTime: '',
    toCallerRealTime: '',

    // sip status
    sipStatus: ''




}

const cloudPhoneSlice = createSlice({
    name: 'CloudPhone',
    initialState,
    reducers: {

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action){
            state.isLoading = false;
            state.error = action.payload;
        },

        setToggleWidjet(state, action) {
            state.toggleWidget = action.payload;
        },

        setShowDialPad(state, action) {
            state.showDialPad = action.payload;
        },

        setShowConversation(state, action) {
            state.showConversation = action.payload;
        },

        setShowPowerDialer(state, action) {
            state.showPowerDialer = action.payload;
        },

        setShowSettings(state, action) {
            state.showSettings = action.payload;
        },

        setShowNav(state, action) {
            state.showNav = action.payload;
        },

        setSelectedCountry(state, action) {
            state.selectedCountry = action.payload;
        },

        setSelectedPhoneNumber(state, action) {
            state.selectedPhoneNumber = action.payload;
        },

        setStartLoadingAllowedCountry(state){
            state.isLoadingAllowedCountry = true;
        },

        setAllowedCountrySuccess(state, action) {
            state.allowedCountry = action.payload;
            state.isLoadingAllowedCountry = false;
        },

        setShowCallFrom(state, action) {
            state.showCallFrom = action.payload;
        },

        setShowCallUsing(state, action) {
            state.showCallUsing = action.payload;
        },

        setSelectedCallUsing(state, action) {
            state.selectedCallUsing = action.payload;
        },

        setStartLoadingCloudNumbers(state){
            state.isLoadingCloudNumbers = true;
        },

        setCloudNumbersSuccess(state, action){
            state.cloudNumbers = action.payload;
            state.isLoadingCloudNumbers = false;
        },

        setSelectedCloudNumberCountry(state, action){
          state.selectedCloudNumberCountry = action.payload
        },

        setSelectedCloudNumber(state, action){
            state.selectedCloudNumber = action.payload
        },

        setShowCalling(state, action){
            state.showCalling = action.payload;
        },

        setTriggerCall(state, action){
            state.triggerCall = action.payload;
        },

        setShowDialing(state, action){
            state.showDialing = action.payload;
        },

        startLoadingContactCheck(state){
            state.isLoadingContactCheck = true
        },

        setCheckPhoneNumberSuccess(state, action){
            state.checkPhoneNumber = action.payload;
            state.isLoadingContactCheck = false;
        },

        setSelectedCustomerName(state, action){
            state.selectedCustomerName = action.payload;
        },

        setSelectedCustomerId(state, action){
            state.selectedCustomerId = action.payload;
        },


        setCreateContactSuccess(state, action){
            state.createContactResponse = action.payload;
            state.isLoading = false;
        },

        startMakeCall(state, action){
            state.isMakingCall = action.payload
        },

        setMakeCallSuccess(state, action){
            state.makeCallResponse = action.payload;
            state.isMakingCall = false;
        },

        setCallingCallSid(state, action){
            state.callingCallSid = action.payload
        },

        startKillingCallLoading(state){
            state.isKillingCallLoading = true
        },

        setKillCallSuccess(state, action){
            state.killCallResponse = action.payload
            state.isKillingCallLoading = false
        },

        stopKillCallLoading(state, action){
          state.isKillingCallLoading = action.payload
        },

        setShowConnected(state, action){
          state.showConnected = action.payload
        },

        setIsRinging(state, action){
            state.isRinging = action.payload
        },

        setIsDialing(state, action){
            state.isDialing = action.payload
        },

        setIsConnected(state, action){
            state.isConnected = action.payload
        },

        setIsFailed(state, action){
            state.isFailed = action.payload
        },


        setIsDisconnected(state, action){
            state.isDisconnected = action.payload
        },

        setIsBusy(state, action){
            state.isBusy = action.payload
        },

        setIsCanceled(state, action){
            state.isCanceled = action.payload
        },

        setIsNoAnswer(state, action){
            state.isNoAnswer = action.payload
        },

        setShowNotes(state, action){
          state.showNotes = action.payload
        },

        setShowTags(state, action){
          state.showTags = action.payload
        },

        setShowAssign(state, action){
          state.showAssign = action.payload
        },

        setCallNotes(state, action){
          state.callNotes = action.payload
        },

        setTagsSuccess(state, action){
          state.tagsResponse = action.payload
          state.isLoading = false
        },

        setSelectedTags(state, action){
          state.selectedTags = action.payload
        },

        setSelectedAssignUser(state, action){
          state.selectedAssignUser = action.payload
        },

        //kill
        setKillConferenceSuccess(state, action){
          state.killCallResponse = action.payload
          state.isKillingCallLoading = false
        },

        setFriendlyName(state, action){
          state.friendlyName = action.payload
        },

        setAddCallNotesSuccess(state, action){
          state.addCallNotesResponse = action.payload
        },

        setKillTriggered(state, action){
          state.killTriggered = action.payload
        },

        setAssignUserSuccess(state, action){
          state.assignUsersResponse = action.payload
          state.isLoading = false
        },

        // app data
        setAppConfigSuccess(state, action){
          state.appConfigResponse = action.payload
          state.isLoading = false
        },

        startLoadingConversations(state){
          state.isLoadingConversation = true
        },

        // conversation
        setConversationAllResponse(state, action){
          state.conversationAllResponse = action.payload
          state.isLoadingConversation = false
        },

        setConversationMissedResponse(state, action){
          state.conversationMissedResponse = action.payload
          state.isLoadingConversation = false
        },

        setRefreshConversation(state, action){
          state.refreshConversation = action.payload
        },

        setTriggerClickToCall(state, action){
          state.triggerClickToCall = action.payload
        },

        setNotesDrawer(state, action){
          state.notesDrawer = action.payload
        },

        setSelectedNotesId(state, action){
          state.selectedNotesId = action.payload
        },

        setGetNotesSuccess(state, action){
          state.notesIdResponse = action.payload
          state.isLoading = false
        },

        setConversationDetailsDrawer(state, action){
          state.conversationDetailsDrawer = action.payload
        },

        setConversationIdSuccess(state, action){
          state.conversationIdResponse = action.payload
          state.isLoading = false
        },

        setIncomingCallTriggered(state, action){
          state.incomingCallTriggered = action.payload
        },

        setTriggerBrowserCall(state, action){
          state.triggerBrowserCall = action.payload
        },

        setIncomingBrowserCall(state, action){
          state.incomingBrowserCall = action.payload
        },

        setIncomingBrowserNumber(state, action){
          state.incomingBrowserNumber = action.payload
        },

        setPowerDialerCallIndex(state, action){
          state.poweDialerCallIndex = action.payload
        },

        setPowerDialerCallTriggered(state, action){
          state.powerDialerCallTriggered = action.payload
        },

        setSipRegistered(state, action){
          state.sipRegistered = action.payload
        },

        setNumberOfWebrtcConnection(state, action){
          state.numberOfWebrtcConnection = action.payload
        },

        setValidateCallFrom(state, action){
          state.validateCallFrom = action.payload
        },

        startPowerDialerLoading(state){
          state.powerDialerListLoading = true
        },

        setGetPowerDialerSuccess(state, action){
          state.powerDialerListResponse= action.payload
          state.powerDialerListLoading = false
        },

        setPowerDialerFilterStatus(state, action){
          state.powerDialerFilterStatus = action.payload
        },

        setPowerDialerTotalCount(state, action){
          state.powerDialerTotalCount = action.payload
        },

        setCallResponseStatus(state, action){
          state.callResponseStatus = action.payload
        },

        setShowCurrentStatus(state, action){
          state.showCurrentStatus = action.payload
        },

        setCurrentStatus(state, action){
          state.currentStatus = action.payload
        },

        setShowTeam(state, action){
          state.showTeam = action.payload
        },

        setShowCallingPattern(state, action){
          state.showCallingPattern = action.payload
        },

        setStatusResponse(state, action){
          state.statusResponse= action.payload
          state.isLoading = false
        },

        setShowBlackList(state, action){
          state.showBlacklist = action.payload
        },

        setBlackListResponse(state, action){
          state.blackListResponse = action.payload
        },

        setUserDetails(state, action){
          state.userDetails = action.payload
        },

        setOnCall(state, action){
          state.onCall = action.payload
        },

        // transfer

        setShowTransferType(state, action){
          state.showTransferType = action.payload
        },

        setShowTransferTeam(state, action){
          state.showTransferTeam = action.payload
        },

        setShowTransferOther(state, action){
          state.showTransferOther = action.payload
        },

        setShowTransferOptions(state, action){
          state.showTransferOptions = action.payload
        },
        
        setSelectedTransferAgent(state, action){
          state.selectedTransferAgent = action.payload
        },

        setSelectedTransferContact(state, action){
          state.selectedTransferContact = action.payload
        },

        setSelectectedTranferContactNumber(state, action){
          state.selectectedTranferContactNumber = action.payload
        },

        setIsTransferActivate(state, action){
          state.isTransferActivate = action.payload
        },

        setReactTimeData(state, action){
          state.realtimeData = action.payload
        },

        setRealTimeLoading(state, action){
          state.realtimeLoading = action.payload
        },

        setTransferLoading(state, action){
          state.transferLoading = action.payload
        },

        setExistingCallData(state, action){
          state.existingCallData = action.payload
        },

        setExistingCallDataOfIncomingTransfer(state , action){
          state.existingCallDataOfIncomingTransfer = action.payload
        },

        setExistingCallDataOfIncoming(state , action){
          state.existingCallDataOfIncoming = action.payload
        },
        
        setSelectedTransferAgentNumber(state, action){
          state.selectedTransferAgentNumber = action.payload
        },

        setRealLimeLiveCallConferenceData(state, action){
          state.realLimeLiveCallConferenceData = action.payload
        },

        setIsIncoming(state, action){
          state.isIncoming = action.payload
        },

        setTransferDone(state, action){
          state.transferDone = action.payload
        },

        setTransferShowAlert(state, action){
          state.transferShowAlert = action.payload
        },

        setTransferAlertMessage(state, action){
          state.transferAlertMessage = action.payload
        },

        setTransferStatus(state, action){
          state.transferStatus = action.payload
        },

        setTransferUser(state, action){
          state.transferUser = action.payload
        },

        setTransferInitialLoading(state, action){
          state.transferInitialLoading = action.payload
        },
        
        setActiveCall(state, action){
          state.activeCall = action.payload
        },

        setPhoneHold(state, action){
          state.phoneHold = action.payload
        },

        setCustomerSidOutbound(state, action){
          state.customerSidOutbound = action.payload
        },

        setActiveConferenceId(state, action){
          state.activeConferenceId = action.payload
        },

        setFromCalledRealTime(state, action){
          state.fromCalledRealTime = action.payload
        },

        setToCallerRealTime(state, action){
          state.toCallerRealTime = action.payload
        },

        setSipStatus(state, action){
          state.sipStatus = action.payload
        }

        



        



        


        






    }
})

export const selectcloudPhoneComponent = (state) => state.cloudPhoneComponent;

export const {
    setToggleWidjet,
    setShowDialPad,
    setShowConversation,
    setShowPowerDialer,
    setShowSettings,
    setShowNav,
    setSelectedCountry,
    setSelectedPhoneNumber,
    setAllowedCountry,
    setShowCallFrom,
    setShowCallUsing,
    setSelectedCallUsing,
    setSelectedCloudNumberCountry,
    setSelectedCloudNumber,
    setShowCalling,
    setTriggerCall,
    setShowDialing,
    setSelectedCustomerName,
    setSelectedCustomerId,
    setCallingCallSid,
    setMakeCallSuccess,
    setIsRinging,
    setIsConnected,
    setIsFailed,
    setIsDisconnected,
    setIsBusy,
    setIsDialing,
    setShowNotes,
    setShowTags,
    setShowAssign,
    setCallNotes,
    setSelectedTags,
    setSelectedAssignUser,
    setShowConnected,
    setFriendlyName,
    setKillTriggered,
    setRefreshConversation,
    setIsCanceled,
    setIsNoAnswer,
    setTriggerClickToCall,
    setNotesDrawer,
    setSelectedNotesId,
    setConversationDetailsDrawer,
    setIncomingCallTriggered,
    setTriggerBrowserCall,
    setIncomingBrowserCall,
    setIncomingBrowserNumber,
    setPowerDialerCallIndex,
    setPowerDialerCallTriggered,
    setSipRegistered,
    setNumberOfWebrtcConnection,
    setValidateCallFrom,
    startPowerDialerLoading,
    setPowerDialerFilterStatus,
    setPowerDialerTotalCount,
    setCallResponseStatus,
    setShowCurrentStatus,
    setCurrentStatus,
    setShowTeam,
    setShowCallingPattern,
    setShowBlackList,
    setOnCall,
    stopKillCallLoading,
    setShowTransferType,
    setShowTransferTeam,
    setShowTransferOther,
    setShowTransferOptions,
    setIsTransferActivate,
    setReactTimeData,
    setRealTimeLoading,
    setSelectedTransferAgent,
    setTransferLoading,
    setExistingCallData,
    setExistingCallDataOfIncomingTransfer,
    setExistingCallDataOfIncoming,
    setSelectedTransferAgentNumber,
    setRealLimeLiveCallConferenceData,
    setSelectedTransferContact,
    setSelectectedTranferContactNumber,
    setIsIncoming,
    setTransferDone,
    setTransferShowAlert,
    setTransferAlertMessage,
    setTransferStatus,
    setTransferUser,
    setTransferInitialLoading,
    setActiveCall,
    setPhoneHold,
    setCustomerSidOutbound,
    setActiveConferenceId,
    setFromCalledRealTime,
    setToCallerRealTime,
    setSipStatus

} = cloudPhoneSlice.actions

export default cloudPhoneSlice.reducer




export function makeCall(data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startMakeCall());
      try {
        const response = await axios.post(`/cp/phonecall`, data);
        dispatch(cloudPhoneSlice.actions.setMakeCallSuccess(response.data));
        dispatch(setExistingCallData(response.data.data))
        dispatch(setPhoneHold(false))
        console.log('make call response', response.data.data)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }
  
  export function callHangup(data, onCall) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startKillingCallLoading());
      try {
        const response = await axios.post(`/cp/hangup`, data);
        dispatch(cloudPhoneSlice.actions.setKillConferenceSuccess(response.data));
        
        dispatch(setShowDialing(false))
        dispatch(setShowDialPad(false))
        dispatch(setTriggerCall(false))
        if(onCall){
          dispatch(setShowConnected(true))
        }
        dispatch(setIsDialing(false))
        dispatch(setIsRinging(false))
        dispatch(setIsBusy(false))
        dispatch(setIsFailed(false))
        dispatch(setIsConnected(false))
        if(onCall){
          dispatch(setIsDisconnected(true))
          dispatch(setShowCalling(true))
        }else{
          dispatch(setShowNav(true))
          dispatch(setShowCalling(false))
        }
        dispatch(setKillTriggered(true))
        dispatch(setTriggerClickToCall(false))
        console.log('kill call response', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  export function callTransfer(data, value) {

    return async () => {
      dispatch(setTransferLoading(true));
      dispatch(setTransferInitialLoading(true))
      try {
        const response = await axios.post(`/cp/transfer`, data);
        console.log('transfer response', response)
        dispatch(setTransferUser(response.data.data.Caller))
        dispatch(setTransferDone(true))
        if(value === 'talkfirst'){
          dispatch(setPhoneHold(true))
        }
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  export function callHangupTransfer(data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startKillingCallLoading());
      try {
        const response = await axios.post(`/cp/hangup/transfer`, data);
        console.log('kill transfer call response', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }


  export function callHoldToggle(data) {

    return async () => {
      try {
        const response = await axios.post(`/cp/hold`, data);
        console.log('hold response', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  

  export function addCallsTagsNotes(friendlyName, data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      try {
        const response = await axios.put(`/cp/edit/${friendlyName}`, data);
        dispatch(cloudPhoneSlice.actions.setAddCallNotesSuccess(response.data));
        console.log('add notes response', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  export function getAssignUsers() {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      try {
        const response = await axios.get(`/v2/users?fields=fullName&fields=phone`);
        dispatch(cloudPhoneSlice.actions.setAssignUserSuccess(response.data.data.data));
        console.log('add notes response', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  export function getRealTimeUsers() {

    return async () => {
      dispatch(setRealTimeLoading(true));
      try {
        const response = await axios.get(`/realtime`);
        dispatch(cloudPhoneSlice.actions.setReactTimeData(response.data.data.data));
        console.log('real time res', response)
        dispatch(setRealTimeLoading(false));
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
        dispatch(setRealTimeLoading(false));
      }
    };

  }

  


  export function updatePowerDialerStatus(id, data) {

    console.log('status update', id, data)
    
    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      try {
        const response = await axios.patch(`/v2/power_dialer/${id}`, data);
        //dispatch(cloudPhoneSlice.actions.setAddCallNotesSuccess(response.data));
        console.log('update powerdialer status', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }







  //conversation /api/cp/logs


  export function getConversations(status, offset) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoadingConversations());
      
      try {
        const response = await axios.get(status ? `/cp/logs?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=${offset}&sort=-_id` : `/cp/logs?limit=10&fields=ParentCall&fields=contactId&fields=CallStatus&populate=contactId&fields=ContactNumber&fields=Notes&fields=createdAt&fields=CloudNumber&offset=${offset}&sort=-_id&CallStatus=no-answer&CallStatus=missed&CallStatus=busy&CallStatus=failed`);
        if(status){
          dispatch(cloudPhoneSlice.actions.setConversationAllResponse(response.data.data));
        }else{
          dispatch(cloudPhoneSlice.actions.setConversationMissedResponse(response.data.data));
        }
        console.log('all conversation response', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

    
  export function getConversationId(id) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.get(`/voice/${id}`);
        dispatch(cloudPhoneSlice.actions.setConversationIdSuccess(response.data.data));
        console.log('all conversation response', response)

      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }


  
  export function getNotesId(id) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.get(`/contacts/${id}?fields=Notes`);
        dispatch(cloudPhoneSlice.actions.setGetNotesSuccess(response.data.data.Notes));
        console.log('notes id response', response)

      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  export function updateNotes(id, data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.patch(`/contact/${id}`, data);
        console.log('notes update response', response)
        enqueueSnackbar('Notes Saved',
         { 
            autoHideDuration: 3000,
            variant: 'default',
            persist: true,
            anchorOrigin: {
              horizontal: 'right', vertical: 'top'
            }

        })
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  // power dialer

  export function getPowerDialers(status, offset) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startPowerDialerLoading());
      try {
        const response = await axios.get(status === 'All' ? `/v2/power_dialer?limit=10&offset=${offset}&populate=contactId` : `/v2/power_dialer?limit=10&offset=${offset}&populate=contactId&callStatus=${status}`);
        dispatch(cloudPhoneSlice.actions.setGetPowerDialerSuccess(response.data.data));
        console.log('get Power dialer response', response.data.data)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }


  // user status

  export function getUserStatus() {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.get(`/user/me/status`);
        dispatch(cloudPhoneSlice.actions.setStatusResponse(response.data.data[0]))
        console.log('get user Status', response.data.data[0])
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  export function getUserDtails() {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.get(`/user/me?fields=FirstName&fields=LastName`);
        dispatch(cloudPhoneSlice.actions.setUserDetails(`${response.data.data.FirstName} ${response.data.data.LastName}`))
        console.log('get details',response.data)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }
  
  

  export function addUserStatus(data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.post(`/user/status`, data);
        console.log('get user Status', response)
        enqueueSnackbar('Status Added',
         { 
            autoHideDuration: 3000,
            variant: 'default',
            persist: true,
            anchorOrigin: {
              horizontal: 'right', vertical: 'top'
            }

        })

      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }
  
  export function updateUserStatus(id, data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.patch(`/user/status/${id}`, data);
        console.log('get user Status', response)
        enqueueSnackbar('Status Updated',
         { 
            autoHideDuration: 3000,
            variant: 'default',
            persist: true,
            anchorOrigin: {
              horizontal: 'right', vertical: 'top'
            }

        })

      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }


  // blacklist

  export function getBlackList() {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      try {
        const response = await axios.get(`/blocklist`);
        dispatch(cloudPhoneSlice.actions.setBlackListResponse(response.data.data.data))
        console.log('getBlackList',response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }

  export function addBlockList(data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.post(`/blocklist`, data);
        console.log('blocklist response', response)
        enqueueSnackbar('Added',
         { 
            autoHideDuration: 3000,
            variant: 'default',
            persist: true,
            anchorOrigin: {
              horizontal: 'right', vertical: 'top'
            }

        })
        dispatch(getBlackList())

      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }
  


  export function deleteBlockList(id, data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      
      try {
        const response = await axios.delete(`/blocklist/${id}`);
        console.log('get user Status', response)
        enqueueSnackbar('Deleted',
         { 
            autoHideDuration: 3000,
            variant: 'default',
            persist: true,
            anchorOrigin: {
              horizontal: 'right', vertical: 'top'
            }

        })
        dispatch(getBlackList())

      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }
  
  



// common
// allowed country
export function getAllowedCountry() {
   
    return async () => {

      dispatch(cloudPhoneSlice.actions.setStartLoadingAllowedCountry());

      try {
        const response = await axios.get(`user/me?fields=country_allowed`);

          dispatch(cloudPhoneSlice.actions.setAllowedCountrySuccess(response.data.data.country_allowed));
 
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
        
      }
    };
  }

  

// cloud numbers 

export function getCloudNumbers() {

    return async () => {
      dispatch(cloudPhoneSlice.actions.setStartLoadingCloudNumbers());
      try {
        const response = await axios.get(`/cp/cloudnumbers`);
          dispatch(cloudPhoneSlice.actions.setCloudNumbersSuccess(response.data.data.data));
          
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };
  }

  export function getContactByPhoneNumber(number) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoadingContactCheck());
      try {
        const response = await axios.get(`/cp/contacts?phoneNumber=${number}`);
          dispatch(cloudPhoneSlice.actions.setCheckPhoneNumberSuccess(response.data));
          localStorage.setItem('liveCallContact', JSON.stringify(response.data.data.data[0]))
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };
  }

  export function createContact(data) {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      try {
        const response = await axios.post(`/insertmany/contacts`, data);
        dispatch(cloudPhoneSlice.actions.setCreateContactSuccess(response.data));
        console.log('create contact response', response)
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };

  }


  export function getTags() {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      try {
        const response = await axios.get(`user/me?fields=Tags`);
          dispatch(cloudPhoneSlice.actions.setTagsSuccess(response.data.data.Tags));
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };
  }


  export function getAppConfig() {

    return async () => {
      dispatch(cloudPhoneSlice.actions.startLoading());
      try {
        const response = await axios.get(`/me/app-config?app_name=cloudphone`);
          dispatch(cloudPhoneSlice.actions.setAppConfigSuccess(response.data.data.data));
      } catch (error) {
        dispatch(cloudPhoneSlice.actions.hasError(error));
      }
    };
  }






  

  // common


 