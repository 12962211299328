
import { createSlice } from "@reduxjs/toolkit";

// utils
import axios, { REACT_APP_URL } from 'src/Utils/axios';

import { enqueueSnackbar } from "notistack";

//
import { dispatch } from '../../store';
import axiosInstance from "src/Utils/axios";


const userID = localStorage.getItem('userID')
const authID = localStorage.getItem('authID')



const initialState = {
  isLoading: false,
  keyLoading: false,
  isContactListLoading: false,
  error: null,
  contactDataList: {},
  contactDataDetails: {},
  contactDeleteResponse: {},
  contactCustomFieldData: [],
  contactCustomFieldPostData: {},
  contactCustomFieldPutData: {},
  contactCustomFieldDeleteResponse: {},
  contactTagTableResponse: {},
  callsConversationData: {},
  contactSearchData: {},
  statusResponse: {},
  updateColSettingsResponse: {},
  getDetailsByNumberResponse: {},
  getSearchByNameResponse: {},
  keyAvailabilityResponse: false,
  contactsDeleteManyResponse:{},
  bulkAssignUserResponse: {},
  contactFilterResponse: {},
  filterLoading: false,
  notes: [],
  isLoadingNotes: false,
  editFieldDialog: false,
  editFieldProps: [],
  customFieldsLoading: false,

}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,

  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    setContactListLoading(state, action){
      state.isContactListLoading = action.payload
    },
    // START LOADING
    startFilterLoading(state) {
      state.filterLoading = true;
    },
    // START LOADING
    stopFilterLoading(state) {
      state.filterLoading = false;
    },
    startKeyLoading(state) {
      state.keyLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
            
    },
        
    // GET all contacts
    getAllContactsSuccess(state, action) {
      state.contactDataList = action.payload
    },

    // contact details
    getContactsDetailSuccess(state, action) {
      state.contactDataDetails = action.payload;
      state.isLoading = false;
    },

    // delete contact
    deleteContactSuccess(state, action) {
      state.contactDeleteResponse = action.payload;
      state.isLoading = false;
    },

    // post custom fields
    postCusomFieldsSuccess(state, action) {
      state.contactCustomFieldPostData = action.payload;
      state.isLoading = false;
    },

    // put custom fields
    putCusomFieldsSuccess(state, action) {
      state.contactCustomFieldPutData = action.payload;
      state.isLoading = false;
    },

    // get all custom fields
    getAllCusomFieldsSuccess(state, action) {
      state.contactCustomFieldData = action.payload;
      state.isLoading = false;
    },

    // put tag
    putTagSuccess(state, action) {
      state.contactTagTableResponse = action.payload
      state.isLoading = false;
    },

    // put assign bulk
    putBulkAssignSuccess(state, action) {
      state.bulkAssignUserResponse = action.payload
      state.isLoading = false;
    },

    // put status
    putStatusSuccess(state, action) {
      state.statusResponse = action.payload
      state.isLoading = false;
    },

    // delete contact
    deleteCustomFieldSuccess(state, action) {
      state.contactCustomFieldDeleteResponse = action.payload;
      state.isLoading = false;
    },

    contactDeleteManySuccess(state, action){
      state.contactsDeleteManyResponse = action.payload;
      state.isLoading= false
    },

    // contact details
    getConversationCalls(state, action) {
      state.callsConversationData = action.payload;
      state.isLoading = false;
    },

    // GET Contact search.
    getConatactSearchSuccess(state, action) {
      state.contactSearchData = action.payload;
      state.isLoading = false;
    },

    updateColSettingsSuccess(state, action){
      state.updateColSettingsResponse = action.payload
      state.isLoading = false
    },

    getContactsDetailByNumberSuccess(state,action){
      state.getDetailsByNumberResponse = action.payload
      state.isLoading = false
    },

    getConatactByNameSuccess(state,action){
      state.getSearchByNameResponse = action.payload
      state.isLoading = false
    },

    getKeyAvailabilityResponse(state,action){
      state.keyAvailabilityResponse = action.payload
      state.keyLoading = false
    },

    filterSuccess(state,action){
      state.contactFilterResponse = action.payload
      state.filterLoading = false
    },

    setIsNotesLoading(state, action){
      state.isLoadingNotes = action.payload
    },

    setUpdateNote(state, action){
      state.notes = action.payload
      state.isLoadingNotes = false
    },

    setEditFieldDialog(state, action){
      state.editFieldDialog = action.payload
    },

    setEditFieldProps(state, action){
      state.editFieldProps = action.payload
    },

    setCustomFieldsLoading(state, action){
      state.customFieldsLoading = action.payload
    },
    

    
    


  }
})



export const selectAllContactsData = (state) => state.contacts;

export default contactsSlice.reducer


// Actions

export function getAllContacts(offsetCount, rowsPerPage) {
  return async () => {
    dispatch(contactsSlice.actions.setContactListLoading(true));
    try {
      const response = await axios.get(`/contacts?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      if(response.data.status === true){
        dispatch(contactsSlice.actions.getAllContactsSuccess(response.data.data));
      }
      dispatch(contactsSlice.actions.setContactListLoading(false));
 
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(contactsSlice.actions.setContactListLoading(false));
    }
  };
}

export function getContactDetail(id) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts/${id}?populate=CustomVariables`);
      dispatch(contactsSlice.actions.getContactsDetailSuccess(response.data.data));

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}

export function getContactDetailByNumber(number) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?phoneNumber=${number}`);
      dispatch(contactsSlice.actions.getContactsDetailByNumberSuccess(response.data));

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}

export function deleteContact(id) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.delete(`/contact/${id}`);
      dispatch(contactsSlice.actions.getContactsDetailSuccess(response.data));
      dispatch(getAllContacts(0,10))
      enqueueSnackbar('Contact Delete Success', {
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Contact Delete Failed', {
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });

    }
  };
}

export function deleteContactMany(data) {

 
  enqueueSnackbar('Delete on Progress!', {
    autoHideDuration: 3000
  });

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.post(`/deletemany/contacts`, data);
      dispatch(contactsSlice.actions.contactDeleteManySuccess(response.data));
      if (response.data.status === true) {
        enqueueSnackbar(`Hurray.. ${response.data.data} Contacts Successfully Deleted`, {
            autoHideDuration: 3000
          });
     
      } else {
        enqueueSnackbar('Oops.. Deleting Failed!', {
            autoHideDuration: 3000
          });
       
      }
      dispatch(getAllContacts(0, 10))
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}


export function postCustomFields(data) {

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.post(`/customfields`, data);
      dispatch(contactsSlice.actions.postCusomFieldsSuccess(response.data));
      if (response.data.status === true) {
        enqueueSnackbar('Hurray.. Field Successfully Added!', {
            autoHideDuration: 3000
          });
      } else {
        enqueueSnackbar('Oops.. Field Adding Failed!', {
            autoHideDuration: 3000
          });
      }
      dispatch(getAllCustomFields(authID, userID))
    } catch (error) {
        enqueueSnackbar('Oops.. Field Adding Failed!', {
            autoHideDuration: 3000
          });
      dispatch(contactsSlice.actions.hasError(error));
    }
  };
}

export function putCustomFields(id, data) {
 
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.put(`/customfields/${id}`, data);
      dispatch(contactsSlice.actions.putCusomFieldsSuccess(response.data));
      if (response.data.status === true) {
        enqueueSnackbar('Oops.. Field Adding Success!', {
            autoHideDuration: 3000
          });
      } else {
        enqueueSnackbar('Oops.. Field Update Failed!', {
            autoHideDuration: 3000
          });
      }
      dispatch(getAllCustomFields(authID, userID))
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Oops.. Field Update Failed!', {
        autoHideDuration: 3000
      });
    }
  };
}

        
export function updateTag(id, data) {

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axiosInstance.patch(`/contacts/${id}`, data);
      dispatch(contactsSlice.actions.putTagSuccess(response.data));
      if (response.data.status === true) {
        enqueueSnackbar('Oops.. Tag Update Success!', {
            autoHideDuration: 3000
          });
      } else {
        enqueueSnackbar('Oops.. Tag Update Failed!', {
            autoHideDuration: 3000
          });
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Oops.. Tag Update Failed!', {
        autoHideDuration: 3000
      });
    }
  };
}

export function updateBulkAssignUser(id, data, offset, limit) {

  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.patch(`/contact/${id}`, data);
      dispatch(contactsSlice.actions.putBulkAssignSuccess(response.data));
      if (response.data.status === true) {
        enqueueSnackbar('Bulk Assign Successfully Updated!', {
            autoHideDuration: 3000
          });
          dispatch(getAllContacts(offset, limit));
      } else {
        enqueueSnackbar('Oops.. Bulk Assign Update Failed!', {
            autoHideDuration: 3000
          });
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Oops.. Bulk Assign Update Failed!', {
        autoHideDuration: 3000
      });
    }
  };
}

export function updateStatus(id, data) {


  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.patch(`/contacts/${id}`, data);
      dispatch(contactsSlice.actions.putStatusSuccess(response.data));
      if (response.data.status === true) {
        enqueueSnackbar('Status Update Success', {
            autoHideDuration: 3000
          });
      } else {
        enqueueSnackbar('Oops.. Status Update Failed!', {
            autoHideDuration: 3000
          });
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Oops.. Status Update Failed!', {
        autoHideDuration: 3000
      });
    }
  };
}



export function getAllCustomFields(limit, offset) {
  return async () => {
    dispatch(contactsSlice.actions.setCustomFieldsLoading(true));
    try {
      const response = await axios.get(`/customfields?&sort=-_id&modules=contacts&limit=${limit}&offset=${offset}`);
      dispatch(contactsSlice.actions.setCustomFieldsLoading(false));
      dispatch(contactsSlice.actions.getAllCusomFieldsSuccess(response.data.data));

    } catch (error) {
      dispatch(contactsSlice.actions.setCustomFieldsLoading(false));
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}


export function deleteCustomField(id) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.delete(`/customfields/${id}`);
      dispatch(contactsSlice.actions.deleteCustomFieldSuccess(response.data));
      dispatch(getAllCustomFields(authID, userID))
      if (response.data.status === true) {
        enqueueSnackbar('Field Successfully Deleted', {
            autoHideDuration: 3000
          });

      } else {
        enqueueSnackbar('Field Delete Failed', {
            autoHideDuration: 3000
          });
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Field Deleted Failed', {
        autoHideDuration: 3000
      });
    }
  };
}


export function getCalls(number, rowsPerPage, offsetCount) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contact/cdr?contactNumber=${number}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      dispatch(contactsSlice.actions.getConversationCalls(response.data));

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));

    }
  };
}


export function SearchCalls(number, rowsPerPage, offsetCount) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?phoneNumber=${number}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      dispatch(contactsSlice.actions.getConatactByNameSuccess(response.data));

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
    }
  };
}

export function SearchCallsByName(name, rowsPerPage, offsetCount) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.get(`/contacts?fullName=${name}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
      dispatch(contactsSlice.actions.getConatactByNameSuccess(response.data));

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
    }
  };
}


export function updateColSettings(id, dragData, showData) {
  return async () => {
    dispatch(contactsSlice.actions.startLoading());
    try {
      const response = await axios.put(`/users/col/${id}`, {"contactColumnSettings": dragData, "contactColumn": showData} );
        dispatch(contactsSlice.actions.updateColSettingsSuccess(response.data));
        if(response.data.status === true){
            enqueueSnackbar('Column Changes Saved', {
                autoHideDuration: 3000
              });
        }else{
            enqueueSnackbar('Oops.. Trouble to Save Change', {
                autoHideDuration: 3000
              });
        }
       
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Oops.. Trouble to Save Change', {
        autoHideDuration: 3000
      });
    }
  };
}

export function getKeyAvailability(key) {
  return async () => {
    dispatch(contactsSlice.actions.startKeyLoading());
    try {
      const response = await axios.get(`/exist/customfields?keyName=${key}`);
      dispatch(contactsSlice.actions.getKeyAvailabilityResponse(true));

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(contactsSlice.actions.getKeyAvailabilityResponse(false));
    }
  };
}


export function contactFilter(assignUser,status, rowsPerPage, offsetCount, tags, custom_variable) {
  return async () => {
    dispatch(contactsSlice.actions.startFilterLoading());
    var url = `/contacts?AssignUser=${assignUser}&isActive=${status}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id&customVariable=${JSON.stringify(custom_variable)}`;
    if (custom_variable === undefined || custom_variable === null) {
      url = `/contacts?AssignUser=${assignUser}&isActive=${status}&limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`;
    }
    try {
      const response = await axios.get(url);
      dispatch(contactsSlice.actions.filterSuccess(response.data));

    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      dispatch(contactsSlice.actions.stopFilterLoading());
    }
  };
}


export function updateNotes(id, data) {


  return async () => {
    dispatch(contactsSlice.actions.setIsNotesLoading(true));
    try {
      const response = await axiosInstance.patch(`/contact/${id}`, data);
      dispatch(contactsSlice.actions.setUpdateNote(response.data));
      dispatch(contactsSlice.actions.setIsNotesLoading(false));
      if (response.data.status === true) {
        enqueueSnackbar('Notes Update Success', {
            autoHideDuration: 3000
          });
      } else {
        enqueueSnackbar('Oops.. Notes Update Failed!', {
            autoHideDuration: 3000
          });
      }
    } catch (error) {
      dispatch(contactsSlice.actions.hasError(error));
      enqueueSnackbar('Oops.. Notes Update Failed!', {
        autoHideDuration: 3000
      });
      dispatch(contactsSlice.actions.setIsNotesLoading(false));
    }
  };
}


export const { 
    
  stopFilterLoading,
  setIsNotesLoading,
  setEditFieldDialog,
  setEditFieldProps,
  setCustomFieldsLoading
  
} = contactsSlice.actions
