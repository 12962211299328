import {createSlice} from '@reduxjs/toolkit';
import axios from 'src/Utils/axios';

import {dispatch} from 'src/Redux/store';

const initialState = {
    isLoading : false,
    error : null,
    callDurationData : {},
    hourlyStatsData : {},
    smsStatsData : [],
    selectedTimeStamp: {timeStamp: 'This Week' , startDate : '', endDate:''},
    userVoiceAnalytics : {},
    subUsers : [],
    listenCallResponse : {}
}

const dashboardSlice = createSlice({
    name : "Dashboard",
    initialState ,
    reducers : {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            
        },

        getDurationStatsSuccess (state , action){
            state.callDurationData = action.payload;
            state.isLoading = false;
        },

        getHourlyStatsSuccess (state , action){
            state.hourlyStatsData = action.payload;
            state.isLoading = false;
        },

        getUserVoiceAnalytics (state , action){
            state.userVoiceAnalytics = action.payload;
            state.isLoading = false; 

            if(action.payload.status){
                state.subUsers = action.payload.data.data
            }
        },

        getListenCallResponse (state , action){
            state.listenCallResponse = action.payload;
            state.isLoading = false;
        },

        getSmsStatsData (state , action ){
            state.smsStatsData = action.payload;
            state.isLoading = false;
        },

        setSelectedTimeStamp : (state , value) =>{
            console.log("Selected Time Stamp In Dashboard Redux : ", value)
            if(value.payload.timeStamp !== 'custom'){
                state.selectedTimeStamp.timeStamp = value.payload.timeStamp
            }else{
                state.selectedTimeStamp.timeStamp = value.payload.timeStamp
                state.selectedTimeStamp.startDate = value.payload.startDate
                state.selectedTimeStamp.endDate = value.payload.endDate
            }
            
        }

    }
})

export const selectAllDashboardData = (state) => state.dashboard;

export const {setSelectedTimeStamp} = dashboardSlice.actions

export default dashboardSlice.reducer

//Actions

export function getDashboardDurationStats(timeSource , startDate , endDate){
    return async ()=>{
        dispatch(dashboardSlice.actions.startLoading())
        try{
            let link = `/dashboard/call_details?timeSource=${timeSource}&startDate=${startDate}&endDate=${endDate}`;
            const response = await axios.get(link)
            dispatch(dashboardSlice.actions.getDurationStatsSuccess(response.data))
            console.log("dashboard duration stats : ", response.data)
        }catch(error){
         dispatch(dashboardSlice.actions.hasError(error));
        }
    }
}

export function getDashboardHourlyStats(AccountSid , startTime , endTime){
    return async ()=>{
        dispatch(dashboardSlice.actions.startLoading())
        try{
            console.log("dashboard hourly stats start" , AccountSid , startTime , endTime)
            let link =`/dashboard/hourly_stats?AccountSid=${AccountSid}&startDate=${startTime.toISOString()}&endDate=${endTime.toISOString()}`
            //let link =`/dashboard/hourly_stats?AccountSid=${AccountSid}`
            console.log("dashboard hourly stats start" , link)
            const response = await axios.get(link)
            console.log("dashboard hourly stats 1 : ", response.data)
            dispatch(dashboardSlice.actions.getHourlyStatsSuccess(response.data))
            console.log("dashboard hourly stats 2 : ", response.data)
        }catch(error){
         dispatch(dashboardSlice.actions.hasError(error));
        }
    }
}

export function getUserVoiceAnalytics(AccountSid , startTime , endTime){
    return async ( )=>{
        dispatch(dashboardSlice.actions.startLoading())

        try{

            console.log("Analytics Details in Redux  : ", AccountSid , startTime , endTime)
            const link = `/analytics/voice/user/stats`

            const data = {
                "auth_id":AccountSid,
                "startDate":startTime,
                "endDate":endTime
            }

            const response = await axios.post(link , data)
            console.log("Response in Analytics : ", response.data)

            dispatch(dashboardSlice.actions.getUserVoiceAnalytics(response.data))

        }catch(error){
            dispatch(dashboardSlice.actions.hasError(error));
        }
    }
}

export function makeApiCallToListenRunningCall(data){
    return async ( )=>{
        dispatch(dashboardSlice.actions.startLoading())

        try{

            console.log("Listen Call Details in Redux  : ", data)
            const link = `/ide/final`

            const response = await axios.post(link , data)
            console.log("Response in Listen Call : ", response.data)

            dispatch(dashboardSlice.actions.getListenCallResponse(response.data))

        }catch(error){
            dispatch(dashboardSlice.actions.hasError(error));
        }
    }
}

export function createUserAuditLogs  (data){
   
    return async ()=>{
        try{

            const link = `/user_activity`
            const response = await axios.post(link , data)
            console.log("Data to audit logs : ", data  , response)
        }catch(error){
            console.log("Error Data to audit logs : ", error)
            dispatch(dashboardSlice.actions.hasError(error));
        }
    }
}

const calculatePercentage = (value , total)=>{
    console.log("percentage function value : ", value , total)
    const currentValue = value ? value : 0;
    const totalValue = total ? total : 0;

    const fraction = currentValue / totalValue

    const percentage = fraction * 100


    return percentage

}   

export function getSmsStats(AccountSid , startTime , endTime){
    return async ()=>{
        dispatch(dashboardSlice.actions.startLoading())
        try{
            let link =`/sms/stats?AccountSid=${AccountSid}&startDate=${startTime.toISOString()}&endDate=${endTime.toISOString()}`
            const response = await axios.get(link)
            console.log("response in sms stats : ", response.data.data)
            let tempArr = [
                {
                    "status": "Delivered",
                    "quantity": response.data.data.sent,
                    "value":  calculatePercentage(response.data.data.sent , response.data.data.total) 
                },
                {
                    "status": "Failed",
                    "quantity": response.data.data.failed,
                    "value":  calculatePercentage(response.data.data.failed ,  response.data.data.total) 
                },
                {
                    "status" : "Queued",
                    "quantity" : response.data.data.queued,
                    "value": calculatePercentage(response.data.data.queued , response.data.data.total) 
                }
            ]
            if(response.data.data.total === 0 ){
                dispatch(dashboardSlice.actions.getSmsStatsData([]))
            }else{
                dispatch(dashboardSlice.actions.getSmsStatsData(tempArr))
            }
            //dispatch(dashboardSlice.actions.getSmsStatsData(tempArr))
            console.log("dashboard sms stats : ",tempArr)
        }catch(error){
         dispatch(dashboardSlice.actions.hasError(error));
        }
    }
}

