import { createSlice } from "@reduxjs/toolkit";

import axios from 'src/Utils/axios';
import { dispatch } from "src/Redux/store";

const initialState= {
    isLoading: false,
    error: null,
    companyData: {},
    usersData: [],
    usersLoading: false,
    inviteUserDialog: false,
}

const getCompanySlice = createSlice({
    name: 'getCompany',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        
        // GET USER DATA SSUCCESS
        getCompanySuccess(state, action) {
          state.companyData = action.payload;
          state.isLoading = false;
        },

        // GET USER DATA SSUCCESS
        getUsersSuccess(state, action) {
          state.usersData = action.payload;
          state.isLoading = false;
        },

        setUsersLoading(state, action) {
          state.usersLoading = action.payload;
        },

        setInviteUserDialog(state, action) {
          state.inviteUserDialog = action.payload;
        },

      
       

    }
})


export const {
  setInviteUserDialog
} = getCompanySlice.actions

export const selectgetCompany = (state) => state.getCompany;

export default getCompanySlice.reducer

// Actions


  export function getCompany(id) {
    return async () => {
      dispatch(getCompanySlice.actions.startLoading());
      try {
        const response = await axios.get(`/companies/${id}`);
          dispatch(getCompanySlice.actions.getCompanySuccess(response.data));
      } catch (error) {
        dispatch(getCompanySlice.actions.hasError(error));
      }
    };
  }

  export function getUsers() {
    return async () => {
      dispatch(getCompanySlice.actions.setUsersLoading(true));
      try {
        const response = await axios.get(`v2/users`);
          dispatch(getCompanySlice.actions.getUsersSuccess(response.data.data.data));
          dispatch(getCompanySlice.actions.setUsersLoading(false));
      } catch (error) {
        dispatch(getCompanySlice.actions.hasError(error));
        dispatch(getCompanySlice.actions.setUsersLoading(false));
      }
    };
  }


