

import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux'
import { store } from './Redux/store'
import App from './App'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { muiKey } from './Utils/axios';
import { LicenseInfo } from '@mui/x-license-pro';
import { StoreProvider } from "easy-peasy";
import appStore from './store/index'
LicenseInfo.setLicenseKey(muiKey);


// Create a root.
const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(

  <Provider store={store}>
     <StoreProvider store={appStore}>
    <BrowserRouter>
      
      <App />
    </BrowserRouter>
    </StoreProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
