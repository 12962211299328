import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage'
// slices
import LoginSlice from './Slices/Auth/Login';
import SignUpSlice from './Slices/Auth/SignUp';
import LayoutSlice from './Slices/Layout/Layout';
import ContactsSlice from './Slices/Contacts/Contacts';
import getCompanySlice from './Slices/Contacts/Company';
import powerDialerSlice from './Slices/Contacts/Powerdialer'
import tagsSlice from './Slices/Contacts/Tags'
import RolesSlice from './Slices/Role/Role';
import ContactGroupSlice from './Slices/Contacts/ContactGroup';
import UserSlice from './Slices/Auth/User';
import ContactStatesSlice from './Slices/Contacts/ContactStates';
import CloudPhoneSlice from './Slices/CloudPhone/CloudPhoneSlice';
import WorkflowSlice from './Slices/Workflow/Workflow';
import InboxVoiceSlice from './Slices/Inbox/Voice'
import InboxOnboardingSlice from './Slices/Inbox/Onboarding'
import SubscriptionSlice from './Slices/Auth/Subscription';
import SmsSlice from './Slices/Inbox/Sms'
import DashboardSlice from './Slices/Dashboard/Dashboard';
import assignedidSlice from './Slices/Inbox/Assignedid'
import CreatedbyidSlice from './Slices/Inbox/CreatedbyId'
import refreshinboxslice from './Slices/Inbox/Refreshinbox'
import userdatashowSlice from './Slices/Inbox/UserDatashow'
import Refreshpageslice from './Slices/Inbox/Refreshpage'
import inboxCommentsReducer from './Slices/Inbox/comments'
import WhatsAppSlice from './Slices/Inbox/WhatsApp';
const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
  };



  const combinedReducer = combineReducers({
    Login: LoginSlice,
    SignUp: SignUpSlice,
    Layout: LayoutSlice,
    contacts: ContactsSlice,
    getCompany:  getCompanySlice,
    powerDialer: powerDialerSlice,
    tags: tagsSlice,
    Roles:  RolesSlice,
    contactsGroup: ContactGroupSlice,
    User: UserSlice,
    contactStates: ContactStatesSlice,
    CloudPhone: CloudPhoneSlice,
    Workflow: WorkflowSlice,
    Voice: InboxVoiceSlice,
    InboxOnboarding: InboxOnboardingSlice,
    Subscription: SubscriptionSlice,
    Sms: SmsSlice,
    WhatsApp: WhatsAppSlice,
    Dashboard: DashboardSlice,
    assignedidSlice: assignedidSlice,
    CreatedbyidSlice: CreatedbyidSlice,
    refreshinboxslice: refreshinboxslice,
    userdatashowSlice: userdatashowSlice,
    Refreshpageslice: Refreshpageslice,
    inboxComments:  inboxCommentsReducer,

    }) 

    const rootReducer = (state, action) => {
      if (action.type === 'Layout/logout') {
        storage.removeItem('persist:root')
        return combinedReducer(undefined, action);
      }
      return combinedReducer(state, action);
    };

    
    export { rootPersistConfig, rootReducer };