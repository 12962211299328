import axios from 'axios';

export const REACT_APP_URL = 'https://launch-api.vibtree.com'
// export const REACT_APP_URL = 'http://localhost:9100'
export const sobotUrl = "http://dev-api.sobot.in"
export const muiKey = '799b4ae9b3ce1d4ca6b943a7f5db24ebTz00Nzg1OSxFPTE2OTAyMjI2NzE2NjksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
export const awsAccessKey = "AKIA2MTCLJCGTO3X7AJJ"
export const awsSecretAccessKey = "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"
export const awsRegion = "ap-south-1"
const axiosInstance = axios.create({
    baseURL: `${REACT_APP_URL}/api`,
    timeout: 30000,
    headers: {
      'jwt-authorization': localStorage.getItem('jwtToken'),
      'Access-Control-Allow-Origin': '*'
    },
  });
  
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  );

  export default axiosInstance;
  