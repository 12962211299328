import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from '../../../Utils/axios';
import { enqueueSnackbar } from "notistack";

import { dispatch } from "../../store";

const initialState= {
    isLoading: false,
    error: null,

    isSendEmaiOtpLoading: false,
    isOtpVerifyLoading: false,
    isPasswordLoading: false,
    showEmailBox: true, // true
    showOtpBox: false,
    showPasswordBox: false, // false
    showSuccessBox: false,
    showAlertLogin: false,
    alertMessageLogin: '',
    emailCheckLoading: false,
    phoneCheckLoading: false,
    emailExist: false,
    phoneExist: false,
    emailOtpValue: '',
    token: '',

}

const SignUpSlice = createSlice({
    name: 'SignUp',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        hasError(state, action){
            state.error = action.payload
        },
        setSendEmaiOtpLoading(state, action){
            state.isSendEmaiOtpLoading = action.payload
        },
        setShowAlertLogin(state, action){
            state.showAlertLogin = action.payload
        },
        setAlertMessageLogin(state, action){
            state.alertMessageLogin = action.payload
        },
        setEmailCheckLoading(state, action){
            state.emailCheckLoading = action.payload
        },
        setPhoneCheckLoading(state, action){
            state.phoneCheckLoading = action.payload
        },
        setEmailExist(state, action){
            state.emailExist = action.payload
        },
        setPhoneExist(state, action){
            state.phoneExist = action.payload
        },
        setShowEmailBox(state, action){
            state.showEmailBox = action.payload
        },
        setShowOtpBox(state, action){
            state.showOtpBox = action.payload
        },
        setShowPasswordBox(state, action){
            state.showPasswordBox = action.payload
        },
        setShowSuccess(state, action){
            state.showSuccessBox = action.payload
        },
        setEmailOtpValue(state, action){
            state.emailOtpValue = action.payload
        },
        setOtpVerifyLoading(state, action){
            state.isOtpVerifyLoading = action.payload
        },
        setSavePasswordLoading(state, action){
            state.isPasswordLoading = action.payload
        },
        setToken(state, action){
          state.token = action.payload
        }
        

    }
})
    
export const { 
    setSendEmaiOtpLoading,
    setShowAlertLogin,
    setAlertMessageLogin,
    setEmailExist,
    setShowEmailBox,
    setShowOtpBox,
    setShowPasswordBox,
    setPhoneExist,
    setEmailOtpValue,
    setOtpVerifyLoading,
    setShowSuccess,
    setToken
} = SignUpSlice.actions


export default SignUpSlice.reducer

export function accessGoogleSignup ( data ){

  return async () => {
    dispatch(SignUpSlice.actions.setSavePasswordLoading(true));
    try {
      const response = await axios.post(`/google/register`, data);
      if(response.data){
        if(response.data.status === false){
          dispatch(SignUpSlice.actions.setSavePasswordLoading(false));
          dispatch(setShowAlertLogin(true))
          dispatch(setAlertMessageLogin(response.data.message))
        }else
        if(response.data.status === true){
          dispatch(setShowPasswordBox(false))
          dispatch(setShowEmailBox(false))
          dispatch(setShowSuccess(true))
          dispatch(SignUpSlice.actions.setSavePasswordLoading(false));
        }
      }
    } catch (error) {
      dispatch(SignUpSlice.actions.hasError(error));
      dispatch(SignUpSlice.actions.setSavePasswordLoading(false));
      dispatch(setShowAlertLogin(true))
      dispatch(setAlertMessageLogin(error.message))
    }
  };
}


export function checkEmailExist(data) {

    return async () => {
      dispatch(SignUpSlice.actions.setEmailCheckLoading(true));
      try {
        const response = await axios.get(`/exist?email=${data}`);
        if(response.data){
          if(response.data.status === false){
            dispatch(SignUpSlice.actions.setEmailCheckLoading(false));
            dispatch(setEmailExist(true))
          }else
          if(response.data.status === true){
            dispatch(setEmailExist(false))
            dispatch(SignUpSlice.actions.setEmailCheckLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin('Vibtree Account Exists with Email ID'))
          }
        }
      } catch (error) {
        dispatch(setEmailExist(true))
        dispatch(SignUpSlice.actions.hasError(error));
        dispatch(SignUpSlice.actions.setEmailCheckLoading(false));
      }
    };

  }

  export function checkPhoneExist(data) {

    return async () => {
      dispatch(SignUpSlice.actions.setPhoneCheckLoading(true));
      try {
        const response = await axios.get(`/exist?phone=${data}`);
        if(response.data){
          if(response.data.status === false){
            dispatch(SignUpSlice.actions.setPhoneCheckLoading(false));
            dispatch(setPhoneExist(true))
          }else
          if(response.data.status === true){
            dispatch(setPhoneExist(false))
            dispatch(SignUpSlice.actions.setPhoneCheckLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin('Vibtree Account Exists with Phone Number'))
          }
        }
      } catch (error) {
        dispatch(setPhoneExist(true))
        dispatch(SignUpSlice.actions.hasError(error));
        dispatch(SignUpSlice.actions.setPhoneCheckLoading(false));
      }
    };

  }

  export function sendEmailOtp(data) {

    return async () => {
      dispatch(SignUpSlice.actions.setSendEmaiOtpLoading(true));
      try {
        const response = await axios.post(`/v2/email/sendotp`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(SignUpSlice.actions.setSendEmaiOtpLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            dispatch(SignUpSlice.actions.setSendEmaiOtpLoading(false));
            dispatch(setShowOtpBox(true))
            dispatch(setShowEmailBox(false))
            enqueueSnackbar( 'OTP successfully sent in your Email',{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              },
              variant:'success'
            })
          }
          // dispatch(setRecentLoginOtp(false))
        }
      } catch (error) {
        dispatch(SignUpSlice.actions.hasError(error));
        dispatch(SignUpSlice.actions.setSendEmaiOtpLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('OTP Sending Failed'))
        // dispatch(setRecentLoginOtp(false))
      }
    };

  }



  export function verifyLoginOtp(data) {

    return async () => {
      dispatch(SignUpSlice.actions.setOtpVerifyLoading(true));
      try {
        const response = await axios.post(`/v2/verifyotp`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(SignUpSlice.actions.setOtpVerifyLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            dispatch(setShowPasswordBox(true))
            dispatch(setShowOtpBox(false))
            dispatch(setToken(response.data.data.token))

          }
        }
      } catch (error) {
        dispatch(SignUpSlice.actions.hasError(error));
        dispatch(SignUpSlice.actions.setOtpVerifyLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('OTP Verification Failed'))
      }
    };

  }

  export function createAccount(data) {

    return async () => {
      dispatch(SignUpSlice.actions.setSavePasswordLoading(true));
      try {
        const response = await axios.post(`/register`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(SignUpSlice.actions.setSavePasswordLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            dispatch(setShowPasswordBox(false))
            dispatch(setShowSuccess(true))
            dispatch(SignUpSlice.actions.setSavePasswordLoading(false));
          }
        }
      } catch (error) {
        dispatch(SignUpSlice.actions.hasError(error));
        dispatch(SignUpSlice.actions.setSavePasswordLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('Reset Password Failed'))
      }
    };

  }

