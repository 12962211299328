import { createSlice } from "@reduxjs/toolkit";
import axios, {sobotUrl} from '../../../Utils/axios';
import { dispatch } from "../../store";
import { enqueueSnackbar } from "notistack";

const initialState= {
    whatsAppLoading:  false,
    whatsAppConversationList: [],
    whatsAppSendLoading: false,
    whatsAppSendData: [],
    whatsAppSendResponse: [],
    whatsAppConversationId: '',
    activeConversation: [],
    activeConversationName: '',
    activeConversationPhone: '',
    activeSendMessages: [],
    contactsDrawer: false,
    seletectedContacts: [],
    newChatLeft: [],
    searchConversationResponse: [],
    sideConversations: [],
    whatsAppUseId : '',
    whatsAppChatOffset: 0,
    whatsAppMessagesLoading: false,
    whatsAppMessages: [],
    templateDialog: false,
    chatActive: true,
    templateData: {},
    selectedTemplate: {},
    addChatTextResponse: {},
    templateActive: false,
    templateLoading: false,
    sendMessageLoading: false,
    socketResponse: [],
    sendTempId: ''

    
}

const WhatsAppSlice = createSlice({
    name: 'WhatsApp',
    initialState,
    reducers: {
        setWhatsAppLoading(state, action){
            state.whatsAppLoading = action.payload
        },
        setWhatsAppConversationList(state, action){
            state.whatsAppConversationList = action.payload
        },
        setWhatsAppSendLoading(state, action){
            state.whatsAppSendLoading = action.payload
        },
        setWhatsAppSendResponse(state, action){
            state.whatsAppSendResponse = action.payload
        },
        setWhatsAppConversationId(state, action){
            state.whatsAppConversationId = action.payload
        },
        setActiveConversation(state, action){
            state.activeConversation = action.payload
        },
        setActiveConversationName(state, action){
          state.activeConversationName = action.payload
          },
          setActiveConversationPhone(state, action){
            state.activeConversationPhone = action.payload
        },
        setActiveSendMessages(state, action){
            state.activeSendMessages = action.payload
        },
        setContactsDrawer(state, action){
              state.contactsDrawer = action.payload
        },
        setSeletectedContacts(state, action){
          state.seletectedContacts = action.payload
        },
        setNewChatLeft(state, action){
          state.newChatLeft = action.payload
        },
        setSearchConversationResponse(state, action){
          state.searchConversationResponse = action.payload
        },
        setSideConversations(state, action){
          state.sideConversations = action.payload
        },
        setWhatsAppUseId(state, action){
          state.whatsAppUseId = action.payload
        },
        setWhatsAppSendData(state, action){
          state.whatsAppSendData = action.payload
        },
        setWhatsAppChatOffset(state, action){
          state.whatsAppChatOffset = action.payload
        },
        setWhatsAppMessagesLoading(state, action){
          state.whatsAppMessagesLoading = action.payload
        },
        setWhatsAppMessages(state, action){
          state.whatsAppMessages = action.payload
        },
        setTemplateDialog(state, action){
          state.templateDialog = action.payload
        },
        setChatActive(state, action){
          state.chatActive = action.payload
        },
        getTemplateSuccess(state, action){
          state.templateData = action.payload
        },

        setSelectedTemplate(state, action){
          state.selectedTemplate = action.payload
        },

        addChatSuccess(state, action) {
          state.addChatTextResponse = action.payload;
          state.isLoading = false;
        },

        setTemplateActive(state, action){
          state.templateActive = action.payload
        },

        setTemplateLoading(state, action){
          state.templateLoading = action.payload
        },

        setSendMessageLoading(state, action){
          state.sendMessageLoading = action.payload
        },

        setSocketResponse(state, action){
          state.socketResponse = action.payload
        },

        setSendTempId(state, action){
          state.sendTempId = action.payload
        }

        



    }
})

export default WhatsAppSlice.reducer

export const {
    setWhatsAppLoading,
    setWhatsAppConversationList,
    setWhatsAppConversationId,
    setActiveConversation,
    setActiveConversationName,
    setActiveConversationPhone,
    setActiveSendMessages,
    setContactsDrawer,
    setSeletectedContacts,
    setNewChatLeft,
    setSearchConversationResponse,
    setSideConversations,
    setWhatsAppUseId,
    setWhatsAppSendData,
    setWhatsAppChatOffset,
    setWhatsAppMessagesLoading,
    setWhatsAppMessages,
    setTemplateDialog,
    setChatActive,
    setSelectedTemplate,
    setTemplateActive,
    setSendMessageLoading,
    setSocketResponse,
    setSendTempId
} = WhatsAppSlice.actions


export function getAllConversations(number, offset, limit) {
    return async () => {
      dispatch(WhatsAppSlice.actions.setWhatsAppLoading(true));
      try {
        const response = await axios.get(`/whatsApp/conversations?populate=lastMessageId&populate=contactId&CloudNumber=${number}&offet=${offset}&limit=${limit}`);
        dispatch(WhatsAppSlice.actions.setWhatsAppConversationList(response.data.data))
        dispatch(WhatsAppSlice.actions.setWhatsAppLoading(false));
      } catch (error) {
        dispatch(WhatsAppSlice.actions.setWhatsAppLoading(false));
      }
    };

  }


  export function sendWhatsApp(data) {
    return async () => {
      dispatch(WhatsAppSlice.actions.setWhatsAppSendLoading(true));
      try {
        const response = await axios.post(`/whatsApp/thinq/send`, data);
        
        console.log('redux send whatsApp', response)
        if(response.data.status === true){
            dispatch(WhatsAppSlice.actions.setWhatsAppSendResponse(response.data))
            dispatch(WhatsAppSlice.actions.setWhatsAppConversationId(response.data.data.conversationId))
        }else{
            enqueueSnackbar(response.data.message, {
              variant: 'error'
            })
        }
        
        dispatch(WhatsAppSlice.actions.setWhatsAppSendLoading(false));
      } catch (error) {
        dispatch(WhatsAppSlice.actions.setWhatsAppSendLoading(false));
        enqueueSnackbar('Message send Failed', {
          variant: 'error'
        })
      }
    };

  }



  export function whatsAppConversationSearchByName(name) {
    return async () => {
      dispatch(WhatsAppSlice.actions.setWhatsAppLoading());
      try {
        const response = await axios.get(`filter/whatsApp/conversations?populate=lastMessageId&name=${name}&populate=contactId`);
        dispatch(WhatsAppSlice.actions.setSearchConversationResponse(response.data.data));
        dispatch(WhatsAppSlice.actions.setWhatsAppLoading(false));
      } catch (error) {
        dispatch(WhatsAppSlice.actions.setWhatsAppLoading(false));
      }
    };
  }


  export function whatsAppConversationSearchByPhone(number) {
    return async () => {
      dispatch(WhatsAppSlice.actions.setWhatsAppLoading());
      try {
        const response = await axios.get(`filter/whatsApp/conversations?populate=lastMessageId&number=${number}&populate=contactId`);
        dispatch(WhatsAppSlice.actions.setSearchConversationResponse(response.data.data));
        dispatch(WhatsAppSlice.actions.setWhatsAppLoading(false));
      } catch (error) {
        dispatch(WhatsAppSlice.actions.setWhatsAppLoading(false));
      }
    };
  }



  export function getMessages(whatsAppConversationId) {
    return async () => {
      dispatch(setWhatsAppMessagesLoading(true));
      try {
        const response = await axios.get(`/whatsApp/messages/${whatsAppConversationId}?sort=-createdAt&offset=0&limit=20`);
        dispatch(setWhatsAppMessages(response.data.data))
        dispatch(setWhatsAppMessagesLoading(false));
      } catch (error) {
        dispatch(setWhatsAppMessagesLoading(false));
      }
    };

  }



  export function addChatTemplate(access_token, data) {

    dispatch(setSendMessageLoading(true))

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/conversations/message/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('response chat', data)
        if(data.code === 400){
            dispatch(setSendMessageLoading(false))
            dispatch(setTemplateDialog(false))
          }
          if(data.code === 200){
            dispatch(setSendMessageLoading(false))
            dispatch(setWhatsAppSendData(data.data))
            dispatch(WhatsAppSlice.actions.addChatSuccess(data.data));
            dispatch(setTemplateDialog(false))
            dispatch(setSendTempId(data.data.id))
          }
      })
      .catch(error => {
        dispatch(setSendMessageLoading(false))
      });
      
      
    };
  }


  export function getTemplates(access_token) {

   
    return async () => {
      dispatch(WhatsAppSlice.actions.setTemplateLoading(true));
      try {
        const response = await axios.get(`${sobotUrl}/templates/?status=1&page=1`, {
            headers: {
              'Authorization': `JWT ${access_token}`
            }
          });
          dispatch(WhatsAppSlice.actions.getTemplateSuccess(response.data));
          dispatch(WhatsAppSlice.actions.setTemplateLoading(false));

          console.log('getTemplates response', response.data)
 
      } catch (error) {
        dispatch(WhatsAppSlice.actions.setTemplateLoading(false));
        
      }
    };
  }

  export function addChatText(access_token, message, msgType, id) {

    dispatch(setSendMessageLoading(true))

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "fb_msg_type": msgType,
      "message": message,
      "conversations_id": id
    });
    


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/conversations/message/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('response chat', data)
        if(data.code === 400){
            dispatch(setSendMessageLoading(false))
            dispatch(WhatsAppSlice.actions.setChatActive(false))
            dispatch(WhatsAppSlice.actions.setTemplateActive(true))
  
          }
          if(data.code === 200){
            dispatch(setSendMessageLoading(false))
            dispatch(setWhatsAppSendData(data.data))
            dispatch(WhatsAppSlice.actions.addChatSuccess(data.data));
            dispatch(setSendTempId(data.data.id))
          }
      })
      .catch(error => {
        dispatch(setSendMessageLoading(false))
      });


      
    };
  }


  export function sendNotes(access_token, data) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization",  `JWT ${access_token}`);
    myHeaders.append("Content-Type", "application/json");



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow'
    };
   
    return async () => {

      fetch(`${sobotUrl}/conversations/private-note/`, requestOptions)
      .then(response =>{
        console.log('whatsapp response', response)
        if(response.status === 400){
          dispatch(WhatsAppSlice.actions.setChatActive(false))
          dispatch(WhatsAppSlice.actions.setTemplateActive(true))
        }
        if(response.status === 200){
          dispatch(WhatsAppSlice.actions.addChatSuccess(response));
        }
      })
      .catch(error => console.log('error', error));


      
    };
  }

