import { createSlice } from '@reduxjs/toolkit'

export const ContactStatesSlice = createSlice({
    name: 'contactStates',
    initialState: {
        openFilterDrawer: false,
    },
    reducers: {

        incrementFilterDrawer: (state, action) => {
            state.openFilterDrawer = action.payload
        },
       
    },
})

// Action creators are generated for each case reducer function
export const { 
    
    incrementFilterDrawer,
    
} = ContactStatesSlice.actions

export default ContactStatesSlice.reducer