import { createSlice } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";

// utils
import axios from 'src/Utils/axios';

import { dispatch } from 'src/Redux/store';


// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    ivrData: [],
    ivrDataLoading: false,
    ivrNewResponse: {},
    ivrDeleteResponse: {},
    removeEdgeFlag: false,
    ivrNameUpdateResponse: {},
    ivrStatusUpdateResponse: {},
    createFlowDialog: false,
    createIvrName: '',
    flowDataId: [],
    flowEditorName: '',
    flowDataIdLoading: false,
    flowNameLoading: false,
    flowLoading: false,
    
    
    // editor nodes
    deleteEdgeId: '',
    deleteNodeId: '',
    selectedNode: [],
    // start
    startCallType: 'incoming-call',
    startInboxList: [],
    startSelectedInbox: '',
    startCloudNumber: '',
    startCloudNumberCountry: '',
    inboxNumbersLoading: false,
    // play audio
    playAudioName: '',
    playAudioUrl: '',
    playAudioSSML: '',
    playAudioType: '',
    playAudioLoop: 0,
    playAudioPause: 0,
    //ivr
    ivrName: '',
    ivrNumberArray: [],
    ivrTimeOut: '',
    ivrLoop: 0,
    ivrPause: 0,
    ivrAudioUrl: '',
    ivrAudioText: '',
    ivrAudioType: '',
    // initiate call - mpc
    mpcName: '',
    mpcCallDistribustion: 'priority',
    mpcCallUsing: 'user',
    mpcCallUsingNumbers: [],
    mpcAudioUrl: '',
    mpcAudioLoop: 0,
    mpcAudioPause: 0,
    mpcAudioText: '',
    mpcAudioType: '',
    mpcWishAudioUrl: '',
    mpcWishAudioText: '',
    mpcWishAudioType: '',
    // Business hour
    bHourName: '',
    bHourOptions: '',
    bHourDays: [],
    bHourHolidays: [],
    // speak
    speakBody: '',
    speakName: '',
    speakVoiceType: '',
    speakLanguageCode: '',
    // voice mail
    voiceMailName: '',
    voiceMailBox: '',
    voiceMailMaxSecond: '',
    voiceMailFinishOnKey: '',
    voiceMailFinishOnSilence: '',

    // api request
    apiRequestMethod: '',
    apiRequestUrl: '',
    apiVariableArray: '',
    apiFunctionAudio: '',
    apiFunctionAudioLoop: '',
    apiFunctionAudioReplay: '',
    apiFunctionttsText: '',
    apiFunctionHeaders: '',
    apiFunctionBody: '',
    apiFunctionParams: '',
    apiFunctionName: '',
    apiFunctionResponse: '',
    apiFunctionTtsType: '',
    apiFunctionSSML: '',



    // drawers
    startDrawer: false,
    playAudioDrawer: false,
    ivrDrawer: false,
    mpcDrawer: false,
    businessDrawer: false,
    speakDrawer: false,
    voiceMailDrawer: false,
    apiDrawer: false,

    //all elements
    allElements: [],

    // audio
    audioUrl: '',
    audioText: '',
    audioType: '',
    audioUrlWish: '',
    audioTextWish: '',
    audioTypeWish: '',
}

const workflowSlice = createSlice({
    name: 'Workflow',
    initialState,
    reducers: {

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET NUMBER SSUCCESS
        getIvrSuccess(state, action) {
            state.ivrData = action.payload;
            state.ivrDataLoading = false;
          },

        // POST NUMBER SSUCCESS
        postNewIvrSuccess(state, action) {
          state.ivrNewResponse = action.payload;
          state.isLoading = false;
        },

        // POST NUMBER SSUCCESS
        deleteIvrSuccess(state, action) {
          state.ivrDeleteResponse = action.payload;
          state.isLoading = false;
        },

         // flag
         changeFlag(state, action) {
          state.removeEdgeFlag = action.payload;
          state.isLoading = false;
        },

        // put name SSUCCESS
        putFlowNameSuccess(state, action) {
          state.ivrNameUpdateResponse = action.payload;
          state.isLoading = false;
        },

        // put status SSUCCESS
        putFlowStatusSuccess(state, action) {
          state.ivrStatusUpdateResponse = action.payload;
          state.isLoading = false;
        },

        setCreateFlowDialog(state, action){
            state.createFlowDialog = action.payload
        },

        setIvrDataLoading(state, action){
            state.ivrDataLoading = action.payload
        },

        setCreateIvrName(state, action){
            state.createIvrName = action.payload
        },

        setFlowDataId(state, action){
            state.flowDataId = action.payload
        },

        setFlowDataIdLoading(state, action){
            state.flowDataIdLoading = action.payload
        },

        setFlowEditorName(state, action){
          state.flowEditorName = action.payload
        },

        setFlowNameLoading(state, action){
          state.flowNameLoading = action.payload
        },


        // editor nodes
        setDeleteEdgeId(state, action){
          state.deleteEdgeId = action.payload
        },
        setDeleteNodeId(state, action){
          state.deleteNodeId = action.payload
        },
        setSelectedNode(state, action){
          state.selectedNode = action.payload
        },
        // start
        setStartCallType(state, action){
          state.startCallType = action.payload
        },
        setStartSelectedInbox(state, action){
          state.startSelectedInbox = action.payload
        },
        setStartInboxList(state, action){
          state.startInboxList = action.payload
        },
        setStartCloudNumber(state, action){
          state.startCloudNumber = action.payload
        },
        setStartCloudNumberCountry(state, action){
          state.startCloudNumberCountry = action.payload
        },
        setStartInboxNumbersLoading(state, action){
          state.inboxNumbersLoading = action.payload
        },
        // play Audio
        setPlayAudioName(state, action){
          state.playAudioName = action.payload
        },
        setPlayAudioUrl(state, action){
          state.playAudioUrl = action.payload
        },
        setPlayAudioSSML(state, action){
          state.playAudioSSML = action.payload
        },
        setPlayAudioType(state, action){
          state.playAudioType = action.payload
        },
        setPlayAudioLoop(state, action){
          state.playAudioLoop = action.payload
        },
        setPlayAudioPause(state, action){
          state.playAudioPause = action.payload
        },
        //ivr
        setIvrName(state, action){
          state.ivrName = action.payload
        },
        setIvrNumberArray(state, action){
          state.ivrNumberArray = action.payload
        },
        setIvrTimeOut(state, action){
          state.ivrTimeOut = action.payload
        },
        setIvrLoop(state, action){
          state.ivrLoop = action.payload
        },
        setIvrPause(state, action){
          state.ivrPause = action.payload
        },
        setIvrAudioUrl(state, action){
          state.ivrAudioUrl = action.payload
        },
        setIvrAudioText(state, action){
          state.ivrAudioText = action.payload
        },
        setIvrAudioType(state, action){
          state.ivrAudioType = action.payload
        },
        // mpc
        setMpcName(state, action){
          state.mpcName = action.payload
        },
        setMpcCallDistribution(state, action){
          state.mpcCallDistribustion = action.payload
        },
        setMpcCallUsing(state, action){
          state.mpcCallUsing = action.payload
        },
        setMpcCallUsingNumbers(state, action){
          state.mpcCallUsingNumbers = action.payload
        },
        setMpcAudioUrl(state, action){
          state.mpcAudioUrl = action.payload
        },
        setMpcAudioLoop(state, action){
          state.mpcAudioLoop = action.payload
        },
        setMpcAudioText(state, action){
          state.mpcAudioText = action.payload
        },
        setMpcAudioType(state, action){
          state.mpcAudioType = action.payload
        },
        setMpcAudioPause(state, action){
          state.mpcAudioPause = action.payload
        },
        setMpcWishAudioUrl(state, action){
          state.mpcWishAudioUrl = action.payload
        },
        setMpcWishAudioText(state, action){
          state.mpcWishAudioText = action.payload
        },
        setMpcWishAudioType(state, action){
          state.mpcWishAudioType = action.payload
        },
        // business
        setBHourName(state, action){
          state.bHourName = action.payload
        },
        setBHourOptions(state, action){
          state.bHourOptions = action.payload
        },
        setBHourDays(state, action){
          state.bHourDays = action.payload
        },
        setBHourHolidays(state, action){
          state.bHourHolidays = action.payload
        },
        // speak
        setSpeakName(state, action){
          state.speakName = action.payload
        },
        setSpeakBody(state, action){
          state.speakBody = action.payload
        },
        setSpeakVoiceType(state, action){
          state.speakVoiceType = action.payload
        },
        setSpeakLanguageCode(state, action){
          state.speakLanguageCode = action.payload
        },
        // voice mail
        setVoiceMailName(state, action){
          state.voiceMailName = action.payload
        },
        setVoiceMailBox(state, action){
          state.voiceMailBox = action.payload
        },
        setVoiceMailMaxSecond(state, action){
          state.voiceMailMaxSecond = action.payload
        },
        setVoiceMailFinishOnKey(state, action){
          state.voiceMailFinishOnKey = action.payload
        },
        setVoiceMailFinishOnSilence(state, action){
          state.voiceMailFinishOnSilence = action.payload
        },
        // api request
        setApiRequestMethod(state, action){
          state.apiRequestMethod = action.payload
        },
        setApiRequestUrl(state, action){
          state.apiRequestUrl = action.payload
        },
        setApiVariableArray(state, action){
          state.apiVariableArray = action.payload
        },
        setApiFunctionAudio(state, action){
          state.apiFunctionAudio = action.payload
        },
        setApiFunctionAudioLoop(state, action){
          state.apiFunctionAudioLoop = action.payload
        },
        setApiFunctionAudioReplay(state, action){
          state.apiFunctionAudioReplay = action.payload
        },
        setApiFunctionttsText(state, action){
          state.apiFunctionttsText = action.payload
        },
        setApiFunctionHeaders(state, action){
          state.apiFunctionHeaders = action.payload
        },
        setApiFunctionBody(state, action){
          state.apiFunctionBody = action.payload
        },
        setApiFunctionParams(state, action){
          state.apiFunctionParams = action.payload
        },
        setApiFunctionName(state, action){
          state.apiFunctionName = action.payload
        },
        setApiFunctionResponse(state, action){
          state.apiFunctionResponse = action.payload
        },
        setApiFunctionTtsType(state, action){
          state.apiFunctionTtsType = action.payload
        },
        setApiFunctionSSML(state, action){
          state.apiFunctionSSML = action.payload
        },

        // drawers
        setStartDrawer(state, action){
          state.startDrawer = action.payload
        },
        setPlayAudioDrawer(state, action){
          state.playAudioDrawer = action.payload
        },
        setIvrDrawer(state, action){
          state.ivrDrawer = action.payload
        },
        setMpcDrawer(state, action){
          state.mpcDrawer = action.payload
        },
        setBusinessDrawer(state, action){
          state.businessDrawer = action.payload
        },
        setSpeakDrawer(state, action){
          state.speakDrawer = action.payload
        },
        setVoiceMailDrawer(state, action){
          state.voiceMailDrawer = action.payload
        },
        setApiDrawer(state, action){
          state.apiDrawer = action.payload
        },
        // all elements
        setAllElements(state, action){
          state.allElements = action.payload
        },
        setFlowLoading(state, action){
          state.flowLoading = action.payload
        },

        // audio

        setAudioUrl(state, action){
          state.audioUrl = action.payload
        },
        setAudioText(state, action){
          state.audioText = action.payload
        },
        setAudioType(state, action){
          state.audioType = action.payload
        },
        setAudioUrlWish(state, action){
          state.audioUrlWish = action.payload
        },
        setAudioTextWish(state, action){
          state.audioTextWish = action.payload
        },
        setAudioTypeWish(state, action){
          state.audioTypeWish = action.payload
        },
        
    }
})


export const selectIvrStudio = (state) => state.ivrStudio;

export default workflowSlice.reducer

export const {
    setCreateFlowDialog,
    setCreateIvrName,
    setFlowEditorName,
    setFlowNameLoading,
    // editor nodes
    setDeleteEdgeId,
    setDeleteNodeId,
    setSelectedNode,
    // start
    setStartCallType,
    setStartSelectedInbox,
    setStartInboxList,
    setStartCloudNumber,
    setStartCloudNumberCountry,
    setStartInboxNumbersLoading,
    // play audio
    setPlayAudioName,
    setPlayAudioUrl,
    setPlayAudioSSML,
    setPlayAudioType,
    setPlayAudioLoop,
    setPlayAudioPause,
    // ivr
    setIvrName,
    setIvrNumberArray,
    setIvrTimeOut,
    setIvrLoop,
    setIvrPause,
    setIvrAudioUrl,
    setIvrAudioText,
    setIvrAudioType,
    // mpc
    setMpcName,
    setMpcCallDistribution,
    setMpcCallUsing ,
    setMpcCallUsingNumbers,
    setMpcAudioUrl,
    setMpcAudioLoop,
    setMpcAudioPause,
    setMpcAudioText,
    setMpcAudioType,
    setMpcWishAudioUrl,
    setMpcWishAudioText,
    setMpcWishAudioType,
    // Business Name
    setBHourName,
    setBHourOptions,
    setBHourDays,
    setBHourHolidays,
    // speak
    setSpeakName,
    setSpeakBody,
    setSpeakVoiceType,
    setSpeakLanguageCode,
    // voice mail
    setVoiceMailName,
    setVoiceMailBox,
    setVoiceMailMaxSecond,
    setVoiceMailFinishOnKey,
    setVoiceMailFinishOnSilence,
    // api request
    setApiRequestMethod,
    setApiRequestUrl,
    setApiVariableArray,
    setApiFunctionAudio,
    setApiFunctionAudioLoop,
    setApiFunctionAudioReplay,
    setApiFunctionttsText,
    setApiFunctionHeaders,
    setApiFunctionBody,
    setApiFunctionParams,
    setApiFunctionName,
    setApiFunctionResponse,
    setApiFunctionTtsType,
    setApiFunctionSSML,
    // drawers
    setStartDrawer,
    setPlayAudioDrawer,
    setIvrDrawer,
    setMpcDrawer,
    setBusinessDrawer,
    setSpeakDrawer,
    setVoiceMailDrawer,
    setApiDrawer,
    //others
    setAllElements,
    setFlowLoading,
    // audio
    setAudioText,
    setAudioUrl,
    setAudioType,
    setAudioTextWish,
    setAudioUrlWish,
    setAudioTypeWish
} = workflowSlice.actions


// Actions

export function getIvrStudioData(offset) {

    return async () => {
      dispatch(workflowSlice.actions.setIvrDataLoading(true));
      try {
        const response = await axios.get(`/workflow?&offset=${offset}&limit=10&sort=-_id&fields=name&fields=createdAt&fields=updatedAt&fields=active`);
          dispatch(workflowSlice.actions.getIvrSuccess(response.data.data));
          
      } catch (error) {
        dispatch(workflowSlice.actions.hasError(error));
        dispatch(workflowSlice.actions.setIvrDataLoading(false));
      }
    };
  }

  export function getFlowById(id) {

    return async () => {
      dispatch(workflowSlice.actions.setFlowDataIdLoading(true));
      try {
        const response = await axios.get(`/workflow/${id}`);
          dispatch(workflowSlice.actions.setFlowDataId(response.data.data));
       
          dispatch(setFlowEditorName(response.data.data.name))
          dispatch(workflowSlice.actions.setFlowDataIdLoading(false));
          
      } catch (error) {
        dispatch(workflowSlice.actions.hasError(error));
        dispatch(workflowSlice.actions.setFlowDataIdLoading(false));
      }
    };
  }

  export function getInboxNumbers() {

    return async () => {
      dispatch(workflowSlice.actions.setStartInboxNumbersLoading(true));
      try {
        const response = await axios.get(`/inbox/view`);
          // dispatch(workflowSlice.actions.setFlowDataId(response.data.data));
    
          dispatch(setStartInboxList(response.data.data.data))
          dispatch(workflowSlice.actions.setStartInboxNumbersLoading(false));
          
      } catch (error) {
        dispatch(workflowSlice.actions.hasError(error));
        dispatch(workflowSlice.actions.setStartInboxNumbersLoading(false));
      }
    };
  }



  export function postNewIvr(data) {


    return async () => {
      dispatch(workflowSlice.actions.startLoading());
      try {
        const response = await axios.post(`/workflow`, data);
          dispatch(workflowSlice.actions.postNewIvrSuccess(response.data));
   
          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Flow Successfully created')
            dispatch(setCreateFlowDialog(false))
            dispatch(getIvrStudioData(0))
            dispatch(setCreateIvrName(''))
          }else{
            enqueueSnackbar('Oops.. Flow creating Failed')
          }
          
      } catch (error) {
          dispatch(workflowSlice.actions.hasError(error));
          enqueueSnackbar('Oops.. Flow creating Failed')
      }
    };
  }

  export function deleteFlow(id) {



    return async () => {
      dispatch(workflowSlice.actions.startLoading());
      try {
        const response = await axios.delete(`/workflow/${id}`);
          dispatch(workflowSlice.actions.deleteIvrSuccess(response.data));
         
          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Flow Successfully Deleted')
     
          }else{
            enqueueSnackbar('Oops.. Flow Deleting Failed')
          }
          
      } catch (error) {
          dispatch(workflowSlice.actions.hasError(error));
          enqueueSnackbar('Oops.. Flow creating Failed')
      }
    };
  }


  export function getIvFlag(data) {

    return async () => {
      dispatch(workflowSlice.actions.startLoading());
      try {
          dispatch(workflowSlice.actions.changeFlag(data));
 
      } catch (error) {
        dispatch(workflowSlice.actions.hasError(error));
      }
    };
  }


  export function updateFlowName(id, data) {
 


    return async () => {
      dispatch(setFlowNameLoading(true));
      try {
        const response = await axios.put(`/workflow/${id}`, data);
          dispatch(workflowSlice.actions.putFlowNameSuccess(response.data));
          dispatch(setFlowNameLoading(false))
     
          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Flow Name Successfully Updated')
          }else{
            enqueueSnackbar('Oops.. Flow update Failed')
          }
          
      } catch (error) {
          dispatch(setFlowNameLoading(false))
          dispatch(workflowSlice.actions.hasError(error));
          enqueueSnackbar('Oops.. Flow update Failed')
      }
    };
  }

  export function updateFlow(id, data) {

    return async () => {
      dispatch(setFlowLoading(true));
      try {
        const response = await axios.put(`/workflow/${id}`, data);
          dispatch(setFlowLoading(false))
       
          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Flow Successfully Updated')
          }else{
            enqueueSnackbar('Oops.. Flow update Failed')
          }
          
      } catch (error) {
          dispatch(setFlowLoading(false))
          dispatch(workflowSlice.actions.hasError(error));
          enqueueSnackbar('Oops.. Flow update Failed')
      }
    };
  }

  export function updateFlowStatus(id, data) {
 


    return async () => {
      dispatch(workflowSlice.actions.startLoading());
      try {
        const response = await axios.put(`/workflow/${id}`, data);
          dispatch(workflowSlice.actions.putFlowStatusSuccess(response.data));
   
          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Flow Status Successfully Updated')
     
          }else{
            enqueueSnackbar('Oops.. Flow update Failed')
          }
          
      } catch (error) {
          dispatch(workflowSlice.actions.hasError(error));
          enqueueSnackbar('Oops.. Flow update Failed')
      }
    };
  }
