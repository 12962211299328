import { createSlice } from "@reduxjs/toolkit";
import axios from 'src/Utils/axios';
import { dispatch } from "../../store";
import { enqueueSnackbar } from "notistack";


const initialState= {
    inboxOnboardingLoading:  false,
    inboxVoiceSelectedCountry: {"name":"India","alpha2Code":"IN","callingCodes":"91","flag":"https://flagcdn.com/in.svg","independent":false},
    inboxVoiceSelectedState: '',
    inboxVoiceAreaCode: 205,
    inboxVoiceSelectedNumberType: '',
    inboxName: '',
    inboxIcon: '',
    activeStep: 0,
    selectedChannel: '',
    selectedVoiceNumber: '',
    selectedUsers: [],
    smsSenderId: '',
    smsPeId: '',
    whatsAppNumber: '',
    whatsAppNumberId: '',
    whatsAppAuthKey: '',
    whatsAppWabaId: '',

    numberLoading: false,
    numberData: [],
    numberPageLimit: 10,
    numberPageOffset: 0,
    alreadyPurchasedNumberShow: false,
    selectedAlreadyNumber: false,
    unUserNumberData: [],
    unUsedNumberCheckLoading: false,
    purchasingNumberType: 'domestic',
}

const InboxOnboardingSlice = createSlice({
    name: 'InboxOnboarding',
    initialState,
    reducers: {
        setInboxOnboardingLoading(state, action){
            state.inboxOnboardingLoading = action.payload
        },
        setActiveStep(state, action){
            state.activeStep = action.payload
        },
        setInboxName(state, action){
            state.inboxName = action.payload
        },
        setInboxIcon(state, action){
            state.inboxIcon = action.payload
        },
        setSelectedChannel(state, action){
            state.selectedChannel = action.payload
        },
        setSelectedVoiceNumber(state, action){
            state.selectedVoiceNumber = action.payload
        },
        setSelectedUsers(state, action){
            state.selectedUsers = action.payload
        },
        setInboxVoiceSelectedCountry(state, action){
            state.inboxVoiceSelectedCountry = action.payload
        },
        setInboxVoiceSelectedState(state, action){
            state.inboxVoiceSelectedState = action.payload
        },
        setInboxVoiceAreaCode(state, action){
            state.inboxVoiceAreaCode = action.payload
        },
        setInboxVoiceSelectedNumberType(state, action){
            state.inboxVoiceSelectedNumberType = action.payload
        },
        setNumberLoading(state, action){
            state.numberLoading = action.payload
        },
        setNumberData(state, action){
            state.numberData = action.payload
        },
        setNumberPageLimit(state, action){
            state.numberPageLimit = action.payload
        },
        setNumberPageOffset(state, action){
            state.numberPageOffset = action.payload
        },
        // sms
        setSmsSenderId(state, action){
            state.smsSenderId = action.payload
        },
        setSmsPeId(state, action){
            state.smsPeId = action.payload
        },
        // whatsapp
        setWhatsAppNumber(state, action){
            state.whatsAppNumber = action.payload
        },
        setWhatsAppNumberId(state, action){
            state.whatsAppNumberId = action.payload
        },
        setWhatsAppAuthKey(state, action){
            state.whatsAppAuthKey = action.payload
        },
        setWhatsAppWabaId(state, action){
            state.whatsAppWabaId = action.payload
        },
        setAlreadyPurchasedNumberShow(state, action){
            state.alreadyPurchasedNumberShow = action.payload
        },
        setUnUsedNumberCheckLoading(state, action){
            state.unUsedNumberCheckLoading = action.payload
        },
        setUnUserNumberData(state, action){
            state.unUserNumberData = action.payload
        },
        setSelectedAlreadyNumber(state, action){
            state.selectedAlreadyNumber = action.payload
        },
        setPurchasingNumberType(state, action){
            state.purchasingNumberType = action.payload
        },

        
        
    }
})

export default InboxOnboardingSlice.reducer

export const {
    setInboxOnboardingLoading,
    setActiveStep,
    setSelectedChannel,
    setSelectedVoiceNumber,
    setSelectedUsers,
    setInboxName,
    setInboxIcon,
    setInboxVoiceSelectedCountry,
    setInboxVoiceSelectedState,
    setInboxVoiceAreaCode,
    setInboxVoiceSelectedNumberType,
    setSmsSenderId,
    setSmsPeId,
    setWhatsAppNumber,
    setWhatsAppNumberId,
    setWhatsAppAuthKey,
    setWhatsAppWabaId,
    setNumberLoading,
    setNumberData,
    setNumberPageLimit,
    setNumberPageOffset,
    setAlreadyPurchasedNumberShow,
    setUnUsedNumberCheckLoading,
    setUnUserNumberData,
    setSelectedAlreadyNumber,
    setPurchasingNumberType
    
} = InboxOnboardingSlice.actions


export function getInternationalNumbers(code) {
    return async () => {
      dispatch(InboxOnboardingSlice.actions.setNumberLoading(true));
      try {
        const response = await axios.get(`/international/numbers/voice?areaCode=${code}`);
        console.log('getInternationalNumbers', response)
        dispatch(InboxOnboardingSlice.actions.setNumberData(response.data));
        dispatch(InboxOnboardingSlice.actions.setNumberLoading(false));
      } catch (error) {
        dispatch(InboxOnboardingSlice.actions.setNumberLoading(false));
      }
    };

  }


  export function getIndiaNumbers(state, type, limit, offset) {
    return async () => {
      dispatch(InboxOnboardingSlice.actions.setNumberLoading(true));
      try {
        const response = await axios.get(`/domestic/numbers/voice?type=${type}&limit=${limit}&offset=${offset}&stateName=${state}`);
        console.log('getIndiaNumbers', response)
        dispatch(InboxOnboardingSlice.actions.setNumberData(response.data.data));
        dispatch(InboxOnboardingSlice.actions.setNumberLoading(false));
      } catch (error) {
        dispatch(InboxOnboardingSlice.actions.setNumberLoading(false));
      }
    };

  }


  export function checkUnusedCloudNumber() {
    return async () => {
      dispatch(InboxOnboardingSlice.actions.setUnUsedNumberCheckLoading(true));
      try {
        const response = await axios.get(`/verify/inbox/view`);
        console.log('checkUnusedCloudNumber', response)
        dispatch(InboxOnboardingSlice.actions.setUnUserNumberData(response.data.data));
        dispatch(InboxOnboardingSlice.actions.setUnUsedNumberCheckLoading(false));
      } catch (error) {
        dispatch(InboxOnboardingSlice.actions.setUnUsedNumberCheckLoading(false));
      }
    };

  }

