import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    setUnderMaintanance
} from 'src/Redux/Slices/Layout/Layout'

import { useSelector, dispatch } from 'src/Redux/store';

export default function UnderMaintanance() {

  const {
    underMaintanance
  } = useSelector((state)=>state.Layout)


  const handleClose = () => {
    dispatch(setUnderMaintanance(false))
  };

  return (
    <div>
      <Dialog
        open={underMaintanance}
        onClose={()=>handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Module Under Maintence"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <lottie-player src="https://assets10.lottiefiles.com/packages/lf20_jOs7DE8lUs.json"  background="transparent"  speed="1"  style={{width: '300px', height: '300px'}}  loop autoplay></lottie-player>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            It's ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}