
import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from 'src/Utils/axios';

import { enqueueSnackbar } from "notistack";

//
import { dispatch } from 'src/Redux/store';


const userID = localStorage.getItem('userID')
const authID = localStorage.getItem('authID')


const initialState= {
    isLoading: false,
    error: null,
    isCreateLoading: false,
    isCreateLoadingText: '',
    contactGroupData: {},
    createGroupResponse: {},
    updateGroupResponse: {},
    deleteGroupResponse: {},
    groupData: {},
    editDrawer: false,
    editParams: {},
}

const contactsGroupSlice = createSlice({
    name: 'contactsGroup',
    initialState,

    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        createLoading(state) {
            state.isCreateLoading = true;
            state.isCreateLoadingText = ''
        },

        // START LOADING
        createLoadingSuccess(state) {
            state.isCreateLoading = false;
            state.isCreateLoadingText = 'Success'
        },

         // START LOADING
         createLoadingFailed(state) {
            state.isCreateLoading = false;
            state.isCreateLoadingText = 'Failed'
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            
        },
        
        // get group
        getGroupsSuccess(state, action) {
            state.contactGroupData = action.payload;
            state.isLoading = false;
        },

        // post group
        postGroupsSuccess(state, action) {
            state.createGroupResponse = action.payload;
            state.isLoading = false;
        },

        // put group
        putGroupSuccess(state, action) {
            state.updateGroupResponse = action.payload;
            state.isLoading = false;
        },

        // put group
        deleteGroupSuccess(state, action) {
            state.deleteGroupResponse = action.payload;
            state.isLoading = false;
        },

        // get group
        getGroupSuccess(state, action) {
            state.groupData = action.payload;
            state.isLoading = false;
        },

        setEditDrawer(state, action){
          state.editDrawer = action.payload
        },

        setEditParams(state, action){
          state.editParams = action.payload
        }
     
    }
})



export const selectAllGroupData = (state) => state.contactsGroup;

export default contactsGroupSlice.reducer


export const {
  setEditDrawer,
  setEditParams
} = contactsGroupSlice.actions




// Actions

export function getAllGroups(offsetCount, rowsPerPage) {
    return async () => {
      dispatch(contactsGroupSlice.actions.startLoading());
      try {
        const response = await axios.get(`/groups?limit=${rowsPerPage}&offset=${offsetCount}&sort=-_id`);
          dispatch(contactsGroupSlice.actions.getGroupsSuccess(response.data));
 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
      }
    };
  }

  export function getGroup(id) {
    return async () => {
      dispatch(contactsGroupSlice.actions.startLoading());
      try {
        const response = await axios.get(`/groups/${id}`);
          dispatch(contactsGroupSlice.actions.getGroupSuccess(response.data));
 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
      }
    };
  }

  
export function createGroup(data) {
    return async () => {
      dispatch(contactsGroupSlice.actions.createLoading());
      try {
        const response = await axios.post(`/groups`, data);
          dispatch(contactsGroupSlice.actions.postGroupsSuccess(response.data));
          dispatch(getAllGroups(0, 999))
          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Group Successfully Created', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            })
            dispatch(contactsGroupSlice.actions.createLoadingSuccess());
           
          }else{
            enqueueSnackbar('Oops.. Failed', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            })
            dispatch(contactsGroupSlice.actions.createLoadingFailed());
          } 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
        enqueueSnackbar('Oops.. Failed', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        })
        dispatch(contactsGroupSlice.actions.createLoadingFailed());
      }
    };
  }

  export function editGroup(id, data) {
    return async () => {
      dispatch(contactsGroupSlice.actions.createLoading());
      try {
        const response = await axios.put(`/groups/${id}`, data);
          dispatch(contactsGroupSlice.actions.putGroupSuccess(response.data));

          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Group Successfully Updated', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            })
            dispatch(contactsGroupSlice.actions.createLoadingSuccess());
          }else{
            enqueueSnackbar('Oops.. Failed', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            })
            dispatch(contactsGroupSlice.actions.createLoadingFailed());
          }

 
      } catch (error) {
        dispatch(contactsGroupSlice.actions.hasError(error));
        enqueueSnackbar('Oops.. Failed', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        })
        dispatch(contactsGroupSlice.actions.createLoadingFailed());
      }
    };
  }


  export function deleteGroup(id) {
    return async () => {
      dispatch(contactsGroupSlice.actions.startLoading());
      try {
        const response = await axios.delete(`/groups/${id}`);
          dispatch(contactsGroupSlice.actions.deleteGroupSuccess(response.data));
          if(response.data.status === true){
            enqueueSnackbar('Hurray.. Successfully Deleted', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            })
            dispatch(getAllGroups(0,10))
          }else{

            enqueueSnackbar('Oops.. Delete Failed', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              }
            })
          }
      } catch (error) {
          dispatch(contactsGroupSlice.actions.hasError(error));

          enqueueSnackbar('Oops.. Delete Failed', {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          })
      }
    };
  }
