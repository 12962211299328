import { Suspense, lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
const Loadable = (Component) => (props) => {
  
    return (
      <Suspense
        fallback={<LoadingScreen message={'Loading Data...'} />}
      >
        <Component {...props} />
      </Suspense>
    );
  };

  export default function Router() {
    return useRoutes([
      {
        path: '/',
        index: true,
        element: <Login />
      },
      {
        path: '/auth/forgetpassword',
        element: <ForgetPassword />
      },
      {
        path: '/auth/signup',
        element: <SignUp />
      },
      {
        path: '/auth/userconfirmation/:token',
        element: <UserConfirmation />
      },
      // {
      //   path: '/auth/invitedsignup',
      //   element: <InvitedSignup />
      // },
      // {
      //   path: '/auth/subscribe',
      //   element: <Subscribe />
      // },
      {
        path: 'dashboard',
        element: (
          <DashboardLayout />
        ),
        children: [
          { element: <Navigate to={`/dashboard/matrix`} replace />, index: true },
          { path: 'matrix', element: <Dashboard /> },
          { path: 'profile', element: <Profile /> },
          { path: 'inbox',
            children: [
              { element: <Navigate to={`/inbox/onboarding`} replace />, index: true },
              { path: 'onboarding', element: <InboxOnboarding /> },
              { path: 'voice/:number', element: <InboxVoice /> },
              { path: 'voice/:number/search', element: <InboxVoice /> },
              { path: 'sms/:number', element: <InboxSms /> },
              { path: 'voice/export/:number', element: <InboxVoiceExport /> },
              { path: 'whatsapp', element: <InboxWhatsapp /> },
            ]
          },
          { path: 'contacts',
            children: [
              { element: <Navigate to={`/contacts/manage`} replace />, index: true },
              { path: 'manage', element: <Contacts /> },
              { path: 'manage/search', element: <Contacts /> },
              { path: 'manage/filter', element: <Contacts /> },
              { path: 'create', element: <ContactCreate /> },
              { path: 'details', element: <ContactCreate /> },
              { path: 'import', element: <ContactImport /> },
              { path: 'account/:id/general', element: <ContactAccount /> },
              { path: 'account/:id/notes', element: <ContactAccount /> },
              { path: 'account/:id/conversation', element: <ContactAccount /> },
              { path: 'groups', element: <ContactGroup /> },
            ]
          },
          { path: 'workflow',
            children: [
              { element: <Navigate to={`/dashboard/workflow/manage`} replace />, index: true },
              { path: 'manage', element: <Workflow /> },
              { path: 'editor/:id', element: <WorkflowEditor /> },
            ]
          },
          { path: 'settings',
            children: [
              { element: <Navigate to={`/dashboard/settings/customfields`} replace />, index: true },
              { path: 'inbox', element: <InboxSettings /> },
              { path: 'inbox/:id', element: <InboxEditSettings /> },
              { path: 'customfields', element: <CustomFields /> },
              { path: 'team', element: <Team /> },
            ]
          },
        ]
      },
      {
        path: '/auth/google/login',
        element: <GoogleLogin />
      },
    ])
}
  
const Login = Loadable(lazy(() => import('src/components/Auth/Login')));
const ForgetPassword = Loadable(lazy(() => import('src/components/Auth/ForgetPassword')));
const SignUp = Loadable(lazy(() => import('src/components/Auth/SignUp')));
const UserConfirmation = Loadable(lazy(() => import('src/components/Auth/UserConfirmation')));
// const InvitedSignup = Loadable(lazy(() => import('src/components/Auth/InvitedSignup')));
const DashboardLayout = Loadable(lazy(() => import('src/Layout/DashboardLayout')))
const Dashboard = Loadable(lazy(() => import('src/components/Dashboard/index')));
const Contacts = Loadable(lazy(() => import('src/components/Contacts')));
const ContactCreate = Loadable(lazy(()=>import('src/components/Contacts/Components/Create')))
const ContactImport = Loadable(lazy(()=> import('src/components/Contacts/ImportContacts/ImportContactMain')))
const ContactAccount = Loadable(lazy(()=> import('src/components/Contacts/Components/ContactAccount')))
const ContactGroup = Loadable(lazy(()=> import('src/components/Contacts/Groups/index')))
const GoogleLogin = Loadable(lazy(()=> import('../components/Auth/GoogleLogin')))
const Workflow = Loadable(lazy(() => import('src/components/Workflow')));
const WorkflowEditor = Loadable(lazy(()=>import('src/components/Workflow/Editor')))
const InboxOnboarding =  Loadable(lazy(()=> import('../components/Inbox/Onboarding/index')))
const InboxVoice =  Loadable(lazy(()=> import('../components/Inbox/Voice/index')))
const InboxVoiceExport =  Loadable(lazy(()=> import('../components/Inbox/Voice/Export/index')))
const InboxSms =  Loadable(lazy(()=> import('../components/Inbox/Sms/Index')))
const InboxWhatsapp =  Loadable(lazy(()=> import('../components/Inbox/Whatsapp/index')))
const InboxSettings = Loadable(lazy(()=> import('../components/Inbox/InboxSettings/index')))
const InboxEditSettings = Loadable(lazy(()=> import('../components/Inbox/InboxSettings/EditSettings')))
const CustomFields =  Loadable(lazy(()=> import('../components/CustomFields/index')))
const Team =  Loadable(lazy(()=> import('../components/Team/index')))
const Profile =  Loadable(lazy(()=> import('../components/Profile/index')))

