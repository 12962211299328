import { createSlice } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
// utils
import axios from './../../../Utils/axios';

import { dispatch } from "../../store";
import { setInviteUserDialog } from "../Contacts/Company";

import {
  getUsers
} from 'src/Redux/Slices/Contacts/Company'

const initialState= {
    isLoading: false,
    error: null,
    UserResponse: false,
    userMe: [],
    inviteUserLoading: false,
    resendLoading: false,
    userDeleteLoading: false,
    blockUserLoading: false
}

const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {

        // START LOADING
        startLoading(state, action) {
            state.isLoading = action.payload
        },

        hasError(state, action){
            state.isLoading = false;
            state.error = action.payload;
        },

        setUserResponse(state, action){
            state.UserResponse = action.payload
            state.isLoading = false
        },

        setUserMe(state, action){
          state.userMe = action.payload
        },

        setInviteUserLoading(state, action){
          state.inviteUserLoading = action.payload
        },

        setResendLoading(state, action){
          state.resendLoading = action.payload
        },

        setUserDeleteLoading(state, action){
          state.userDeleteLoading = action.payload
        },

        setBlockUser(state, action){
          state.blockUserLoading = action.payload
        }

        
    }
})

export default UserSlice.reducer

export const {
  setUserMe,
  setInviteUserLoading,
  setResendLoading,
  setUserDeleteLoading,
  setBlockUser
} = UserSlice.actions



export function getUserDtails() {
    console.log('getUserDtails', 'app')
    return async () => {
      dispatch(UserSlice.actions.startLoading(true));
      try {
        const response = await axios.get(`/user/me`);
        localStorage.setItem('authSecret', response.data.data.auth_secret)
        localStorage.setItem('timeZone', response.data.data.timeZone ? response.data.data.timeZone : 'US/Central')
        localStorage.setItem('viewType', response.data.data.viewType)
        localStorage.setItem('is_admin', response.data.data.is_admin)
        localStorage.setItem('role', response.data.data.Role)
        localStorage.setItem('userID', response.data.data._id)
        localStorage.setItem('authID', response.data.data.auth_id)
        localStorage.setItem('companyId', response.data.data.company_id)
        localStorage.setItem('companyName', response.data.data.company_name)
        localStorage.setItem('isWhatsappActive',	true)
        localStorage.setItem('whatsAppUser',	JSON.stringify({"id":156,"user_type":1,"mobile":"919561878080","email":"rahul@effcode.in","on_boarding":true,"is_active":true,"date_joined":"2022-11-08T16:09:51+05:30","last_login":"2023-05-10T13:31:02.525921+05:30","is_password_set":true,"is_email_verified":true,"name":"Rahul","updated_on":"2023-03-29T19:59:29+05:30","password_updated_at":"2023-05-09T16:06:18+05:30","business":1}))
        localStorage.setItem('whatsappTokenAccess',	'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg1NDMwNTI4LCJpYXQiOjE2ODQyMjA5MjgsImp0aSI6IjgyYTUzYmZkZWY3NzRkY2I4ZDdjYzFhYzZmMWM4YzE4IiwidXNlcl9pZCI6MTQzfQ.duaSvFt6NcLNTCP3DuNo8retMxFzeP5rpRwC0i4snIg')
        dispatch(UserSlice.actions.setUserMe(response.data.data))
      } catch (error) {
        dispatch(UserSlice.actions.hasError(error));
      }
    };

  }
  

  export function inviteUser(data) {

    return async () => {
      dispatch(setInviteUserLoading(true));
      try {
        const response = await axios.post(`/invite/user`, data);
        if(response.data.status === true){
          enqueueSnackbar('Invite Success', {
              variant: 'success'
          })
          dispatch(setInviteUserDialog(false))
        }else{
            enqueueSnackbar('Invite Failed', {
                variant: 'error'
            })
        }
        
        dispatch(setInviteUserLoading(false));
      } catch (error) {
        dispatch(setInviteUserLoading(false));
        enqueueSnackbar('Invite Failed', {
            variant: 'error'
        })
      }
    };

  }

  export function resentInviteUser(data) {

    return async () => {
      dispatch(setResendLoading(true));
      try {
        const response = await axios.post(`/invite/resend`, data);
        if(response.data.status === true){
          enqueueSnackbar('Email Send Success', {
              variant: 'success'
          })
          dispatch(setInviteUserDialog(false))
        }else{
            enqueueSnackbar('Email Send Failed', {
                variant: 'error'
            })
        }
        
        dispatch(setResendLoading(false));
      } catch (error) {
        dispatch(setResendLoading(false));
        enqueueSnackbar('Email Send Failed', {
            variant: 'error'
        })
      }
    };

  }

  export function deleteUser(id) {

    return async () => {
      dispatch(setUserDeleteLoading(true));
      try {
        const response = await axios.delete(`/user/${id}`);
        if(response.data.status === true){
          enqueueSnackbar('User Delete Success', {
              variant: 'success'
          })
          dispatch(setUserDeleteLoading(false))
          dispatch(getUsers())
        }else{
            enqueueSnackbar('User Delete Failed', {
                variant: 'error'
            })
        }
        
        dispatch(setUserDeleteLoading(false));
      } catch (error) {
        dispatch(setUserDeleteLoading(false));
        enqueueSnackbar('User Delete Failed', {
            variant: 'error'
        })
      }
    };

  }

  export function blockUser(id) {

    return async () => {
      dispatch(setBlockUser(true));
      try {
        const response = await axios.delete(`/user/${id}`);
        if(response.data.status === true){
          enqueueSnackbar('User Delete Success', {
              variant: 'success'
          })
          dispatch(setBlockUser(false))
          dispatch(getUsers())
        }else{
            enqueueSnackbar('User Delete Failed', {
                variant: 'error'
            })
        }
        
        dispatch(setBlockUser(false));
      } catch (error) {
        dispatch(setBlockUser(false));
        enqueueSnackbar('User Delete Failed', {
            variant: 'error'
        })
      }
    };

  }

  export function logoutUser(authId, userId) {
    return async () => {
      dispatch(UserSlice.actions.startLoading());
      try {
        const data = {
          "authId": authId,
          "userId": userId,
        }
        await axios.post(`/v2/logout` , data);
      } catch (error) {
        dispatch(UserSlice.actions.hasError(error));
      }
    };
  }

