import { createSlice } from '@reduxjs/toolkit'

export const RolesSlice = createSlice({
    name: 'Roles',
    initialState: {
        checkingPermissionsLoading: false,
        isAdmin: true,
        userPermissions: [
            {
                Workflow: {
                    create: true,
                    delete: true,
                    edit: true,
                    view: true
                },
                ContactsManage: {
                  create: true,
                  delete: true,
                  edit: true,
                  view: true
                },
                ContactsNotes: {
                  create: true,
                  delete: true,
                  edit: true,
                  view: true
                },
                ContactsGroups: {
                  create: true,
                  delete: true,
                  edit: true,
                  view: true
                },
                VoicemailBox: {
                  create: true,
                  delete: true,
                  edit: true,
                  view: true
                }
            }
        ]
    },
    reducers: {

        setCheckingPermissionsLoading: (state, action) => {
            state.checkingPermissionsLoading = action.payload
        },
        setIsAdmin: (state, action) => {
            state.isAdmin = action.payload
        },
       
    },
})

// Action creators are generated for each case reducer function
export const { 
    
    setIsAdmin,
    
} = RolesSlice.actions

export default RolesSlice.reducer