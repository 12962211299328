import { createSlice } from "@reduxjs/toolkit";


const initialState= {
    isLeftNavOpen: true,
    leftNavDeskWidth: 220,
    leftNavCollpase: 85,
    underMaintanance: false,
    cloudPhoneHide: false,
    onlineOffline: false,
}

const LayoutSlice = createSlice({
    name: 'Layout',
    initialState,
    reducers: {

        setLeftNavOpen(state, action){
            state.isLeftNavOpen = action.payload
        },
        setUnderMaintanance(state, action){
            state.underMaintanance = action.payload
        },
        setCloudPhoneHide(state, action){
            state.cloudPhoneHide = action.payload
        },
        setOnlineOffline(state, action){
            state.onlineOffline = action.payload
        },
        logout: state =>{

        }
        
    }
})

export default LayoutSlice.reducer

export const {
    setLeftNavOpen,
    setUnderMaintanance,
    setCloudPhoneHide,
    setOnlineOffline,
    logout
} = LayoutSlice.actions