import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from 'src/Utils/axios';

import { enqueueSnackbar } from "notistack";

import { dispatch } from "src/Redux/store";

const initialState= {
    isLoading: false,
    error: null,
    isOtpSendLoading: false,
    isOtpVerifyLoading: false,
    showLoginNumber: true,
    showLoginNumberOtp: false,
    recentLoginOtp: false,
    loginResponse: [],
    showAlertLogin: false,
    alertMessageLogin: '',
    optLoginMobileNumber: '',
    otpLoginSendOtpResponse: [],
    otpLoginVerifyResponse:[],
    // forget password
    isOtpEmailLoading: false,
    isOtpEmailVerifyLoading: false,
    isSavePasswordLoading: false,
    emailExist: false,
    verifyPasswordResponse: '',
    savePasswordResponse: [],
    emailOtpValue: '',
    phoneOtpValue: '',
    showEmailBox: true,
    showOtpBox: false,
    showPasswordBox: false,
    showPasswordSuccess: false,
    emailCheckLoading: false,

}

const LoginSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {

        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        setIsOtpSendLoading(state, action) {
            state.isOtpSendLoading = action.payload;
        },
        setIsOtpVerifyLoading(state, action) {
            state.isOtpVerifyLoading = action.payload;
        },
        stopLoading(state) {
          state.isLoading = false;
        },
        setEmailOtpLoading(state, action) {
            state.isOtpEmailLoading = action.payload;
        },
        setShowLoginNumber(state, action) {
            state.showLoginNumber = action.payload;
        },
        setShowLoginNumberOtp(state, action) {
            state.showLoginNumberOtp = action.payload;
        },
        setOtpEmailVerifyLoading(state, action) {
            state.isOtpEmailVerifyLoading = action.payload;
        },
        setSavePasswordLoading(state, action) {
            state.isSavePasswordLoading = action.payload;
        },
        
        hasError(state, action){
            state.isLoading = false;
            state.error = action.payload;
        },

        setLoginResponse(state, action){
            state.loginResponse = action.payload
            state.isLoading = false
        },
        setShowAlertLogin(state, action){
            state.showAlertLogin = action.payload
        },
        setAlertMessageLogin(state, action){
            state.alertMessageLogin = action.payload
        },
        setOptLoginMobileNumber(state, action){
          state.optLoginMobileNumber = action.payload
        },
        setOtpLoginSendOtpResponse(state, action){
          state.otpLoginSendOtpResponse = action.payload
          state.isOtpSendLoading = false
        },
        setOtpLoginVerifyResponse(state, action){
          state.otpLoginVerifyResponse = action.payload
          state.isOtpVerifyLoading = false
        },
        setEmailExist(state, action){
          state.emailExist = action.payload
        },
        setEmailOtpValue(state, action){
          state.emailOtpValue = action.payload
        },
        setPhoneOtpValue(state, action){
          state.phoneOtpValue = action.payload
        },
        setShowEmailBox(state, action){
          state.showEmailBox = action.payload
        },
        setShowOtpBox(state, action){
          state.showOtpBox = action.payload
        },
        setShowPasswordBox(state, action){
          state.showPasswordBox = action.payload
        },
        setVerifyPasswordResponse(state, action){
          state.verifyPasswordResponse = action.payload
          state.isOtpEmailVerifyLoading = false
        },
        setSavePasswordResponse(state, action){
          state.savePasswordResponse = action.payload
          state.isSavePasswordLoading = false
        },
        
        setShowPasswordSuccess(state, action){
          state.showPasswordSuccess = action.payload
        },

        setEmailCheckLoading(state, action){
          state.emailCheckLoading = action.payload
        },

        setRecentLoginOtp(state, action){
          state.recentLoginOtp = action.payload
        }

    }
})

export const { 
  setShowAlertLogin,
  setAlertMessageLogin,
  setLoginResponse,
  setIsOtpSendLoading,
  setOptLoginMobileNumber,
  setIsOtpVerifyLoading,
  setEmailExist,
  setEmailOtpLoading,
  setEmailOtpValue,
  setPhoneOtpValue,
  setShowEmailBox,
  setShowOtpBox,
  setShowPasswordBox,
  setOtpEmailVerifyLoading,
  setSavePasswordLoading,
  setShowPasswordSuccess,
  setShowLoginNumber,
  setShowLoginNumberOtp,
  setRecentLoginOtp
 } = LoginSlice.actions


export default LoginSlice.reducer

export function accessGoogleLogin ( data ){

  return async () => {
    dispatch(LoginSlice.actions.startLoading());
    try {
      const response = await axios.post(`/google/login`, data);
      if(response.data){
        if(response.data.status === false){
          dispatch(LoginSlice.actions.stopLoading());
          dispatch(setShowAlertLogin(true))
          dispatch(setAlertMessageLogin(response.data.message))
        }else
        if(response.data.status === true){
          dispatch(LoginSlice.actions.setLoginResponse(response.data));
        }
      }
    } catch (error) {
      dispatch(LoginSlice.actions.hasError(error));
      dispatch(setShowAlertLogin(true))
      dispatch(setAlertMessageLogin(error.message))
    }
  };
}


export function accessLogin(data) {

    return async () => {
      dispatch(LoginSlice.actions.startLoading());
      try {
        const response = await axios.post(`/v2/login`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(LoginSlice.actions.stopLoading());
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            dispatch(LoginSlice.actions.setLoginResponse(response.data));
          }
        }
      } catch (error) {
        dispatch(LoginSlice.actions.hasError(error));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin(error.message))
      }
    };

  }

  export function sendLoginOtp(data) {

    return async () => {
      dispatch(LoginSlice.actions.setIsOtpSendLoading(true));
      try {
        const response = await axios.post(`/v2/phone/sendotp`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(LoginSlice.actions.setIsOtpSendLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            enqueueSnackbar('OTP send successfully',{
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }
            })
            dispatch(LoginSlice.actions.setOtpLoginSendOtpResponse(response.data));
            dispatch(setShowLoginNumber(false))
            dispatch(setShowLoginNumberOtp(true))
          }
          dispatch(setRecentLoginOtp(false))
        }
      } catch (error) {
        dispatch(LoginSlice.actions.hasError(error));
        dispatch(LoginSlice.actions.setIsOtpSendLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin(error.message))
        dispatch(setRecentLoginOtp(false))
      }
    };

  }

  export function verifyLoginOtp(data) {

    return async () => {
      dispatch(LoginSlice.actions.setIsOtpVerifyLoading(true));
      try {
        const response = await axios.post(`/v2/verifyotp`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(LoginSlice.actions.setIsOtpVerifyLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            dispatch(LoginSlice.actions.setOtpLoginVerifyResponse(response.data))
            dispatch(setShowPasswordBox(true))
            dispatch(setShowOtpBox(false))
          }
        }
      } catch (error) {
        dispatch(LoginSlice.actions.hasError(error));
        dispatch(LoginSlice.actions.setIsOtpVerifyLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('OTP Verification Failed'))
      }
    };

  }

  export function sendEmailOtp(data) {

    return async () => {
      dispatch(LoginSlice.actions.setEmailOtpLoading(true));
      try {
        const response = await axios.post(`/v2/email/sendotp`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(LoginSlice.actions.setEmailOtpLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
            dispatch(setEmailOtpValue(''))
          }else
          if(response.data.status === true){
            dispatch(setShowEmailBox(false))
            dispatch(setShowOtpBox(true))
          }
          dispatch(setRecentLoginOtp(false))
        }
      } catch (error) {
        dispatch(LoginSlice.actions.hasError(error));
        dispatch(LoginSlice.actions.setEmailOtpLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('OTP Sending Failed'))
        dispatch(setRecentLoginOtp(false))
        dispatch(setEmailOtpValue(''))
      }
    };

  }

  export function verifyEmailOtp(data) {

    return async () => {
      dispatch(LoginSlice.actions.setOtpEmailVerifyLoading(true));
      try {
        const response = await axios.post(`/v2/verifyotp`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(LoginSlice.actions.setOtpEmailVerifyLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            dispatch(LoginSlice.actions.setVerifyPasswordResponse(response.data.data.token))
            dispatch(setShowPasswordBox(true))
            dispatch(setShowOtpBox(false))
          }
        }
      } catch (error) {
        dispatch(LoginSlice.actions.hasError(error));
        dispatch(LoginSlice.actions.setOtpEmailVerifyLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('OTP Verification Failed'))
      }
    };

  }

  export function savePassword(data) {

    return async () => {
      dispatch(LoginSlice.actions.setSavePasswordLoading(true));
      try {
        const response = await axios.post(`/password`, data);
        if(response.data){
          if(response.data.status === false){
            dispatch(LoginSlice.actions.setSavePasswordLoading(false));
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin(response.data.message))
          }else
          if(response.data.status === true){
            dispatch(setShowPasswordSuccess(true))
            dispatch(setShowPasswordBox(false))
          }
        }
      } catch (error) {
        dispatch(LoginSlice.actions.hasError(error));
        dispatch(LoginSlice.actions.setSavePasswordLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('Reset Password Failed'))
      }
    };

  }


  export function checkEmailExist(data) {

    return async () => {
      dispatch(LoginSlice.actions.setEmailCheckLoading(true));
      try {
        const response = await axios.get(`/exist?email=${data}`);
        if(response.data){
          if(response.data.status === false){
            dispatch(LoginSlice.actions.setEmailCheckLoading(false));
            dispatch(setEmailExist(false))
            dispatch(setShowAlertLogin(true))
            dispatch(setAlertMessageLogin('No Email Registered with Email Id'))
          }else
          if(response.data.status === true){
            dispatch(setEmailExist(true))
            dispatch(LoginSlice.actions.setEmailCheckLoading(false));
          }
        }
      } catch (error) {
        dispatch(setEmailExist(false))
        dispatch(LoginSlice.actions.hasError(error));
        dispatch(LoginSlice.actions.setEmailCheckLoading(false));
        dispatch(setShowAlertLogin(true))
        dispatch(setAlertMessageLogin('No Email Registered with Email Id'))
      }
    };

  }
