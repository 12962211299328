import socketIOClient from "socket.io-client";
import { io } from "socket.io-client";
import {REACT_APP_URL} from "src/Utils/axios";
// export const REACT_APP_URL = 'https://launch-api.vibtree.com'

export const socket = socketIOClient(`${REACT_APP_URL}/dropcodes`,{
    "path": "/websockets",
    "transports": ["polling","websocket"],
    "transportOptions": {
      "polling": {
          "extraHeaders": {
              "authorization": "ApiKey <Key>"
          }
      }
    }
});

export const socketIncoming = io(`${REACT_APP_URL}/inbound`,{
    "path": "/websockets",
    "transports": ["polling","websocket"],
    "transportOptions": {
      "polling": {
          "extraHeaders": {
              "authorization": "ApiKey <Key>"
          }
      }
    }
});

export const socketSms = io(`${REACT_APP_URL}/sms`,{
    "path": "/websockets",
    "transports": ["polling","websocket"],
    "transportOptions": {
      "polling": {
          "extraHeaders": {
              "authorization": "ApiKey <Key>"
          }
      }
    }
});


