import { createSlice } from "@reduxjs/toolkit";

// utils
import axios from './../../../Utils/axios';

import { dispatch } from "../../store";
import { enqueueSnackbar } from "notistack";

const initialState= {
    subscriptionDialog: false,
    subscriptionDrawer: false,
    error: null,
    validateLoading: false,
    validateStatus: '',
    processCouponLoading: false,
    processCouponResponse: [],
    subscriptionLoading: false,
    subscriptionResponse: [],
    trailLoading:  false,
    subscriptionPlan: '',
    loadingSubscriptionExisting: false,
    subscriptionData: [],

}

const SubscriptionSlice = createSlice({
    name: 'Subscription',
    initialState,
    reducers: {
        setSubscriptionDialog(state, action){
          state.subscriptionDialog = action.payload
        },
        setSubscriptionDrawer(state, action){
          state.subscriptionDrawer = action.payload
        },
        setValidateLoading(state, action){
            state.validateLoading = action.payload
          },
          setValidateStatus(state, action){
            state.validateStatus = action.payload
          },
          setProcessCouponLoading(state, action){
            state.processCouponLoading = action.payload
          },
          setProcessCouponResponse(state, action){
            state.processCouponResponse = action.payload
          },
          setSubscriptionLoading(state, action){
            state.subscriptionLoading = action.payload
          },
          setSubscriptionResponse(state, action){
            state.subscriptionResponse = action.payload
          },
          setTrailLoading(state, action){
            state.trailLoading = action.payload
          },
          setSubscriptionPlan(state, action){
            state.subscriptionPlan = action.payload
          },

          setLoadingSubscriptionExisting(state, action){
            state.loadingSubscriptionExisting = action.payload
          },
          setSubscriptionData(state, action){
            state.subscriptionData = action.payload
          },
    }
})

export default SubscriptionSlice.reducer

export const {
    setSubscriptionDialog,
    setSubscriptionDrawer,
    setValidateLoading,
    setValidateStatus,
    setProcessCouponLoading,
    setSubscriptionLoading,
    setSubscriptionResponse,
    setSubscriptionPlan
} = SubscriptionSlice.actions



export function validateCoupen(text) {

    return async () => {
      dispatch(SubscriptionSlice.actions.setValidateLoading(true));
      dispatch(SubscriptionSlice.actions.setValidateStatus(''))
      try {
        const response = await axios.post(`/coupon/isvalid`, {'coupon': text});
        if(response.data.status === true){
            dispatch(SubscriptionSlice.actions.setValidateStatus('valid'))
        }else{
            enqueueSnackbar('Coupen is Not Valid', {
                variant: 'error'
            })
        }
        
        dispatch(SubscriptionSlice.actions.setValidateLoading(false));
      } catch (error) {
        dispatch(SubscriptionSlice.actions.setValidateLoading(false));
        enqueueSnackbar('Coupen is Not Valid', {
            variant: 'error'
        })
        dispatch(SubscriptionSlice.actions.hasError(error));
      }
    };

  }

  export function processCoupen(list) {

    return async () => {
      dispatch(SubscriptionSlice.actions.setProcessCouponLoading(true));
      try {
        const response = await axios.post(`/coupon/verify`, {'coupon': list});
        console.log('processCoupen', response)
        if(response.data.status === true){
            dispatch(SubscriptionSlice.actions.setProcessCouponResponse(response.data.data))
            dispatch(processSubscribe(response.data.data.subscription_id, response.data.data.applied_coupons))
        }else{
            enqueueSnackbar('Subscription Failed', {
                variant: 'error'
            })
        }
        
        dispatch(SubscriptionSlice.actions.setProcessCouponLoading(false));
      } catch (error) {
        dispatch(SubscriptionSlice.actions.setProcessCouponLoading(false));
        enqueueSnackbar('Subscription Failed', {
            variant: 'error'
        })
        dispatch(SubscriptionSlice.actions.hasError(error));
      }
    };

  }

  export function processTrial() {

    return async () => {
      dispatch(SubscriptionSlice.actions.setTrailLoading(true));
      try {
        const response = await axios.post(`/coupon/verify`, {'coupon': []});
        console.log('processCoupen', response)
        if(response.data.status === true){
            dispatch(SubscriptionSlice.actions.setProcessCouponResponse(response.data.data))
            dispatch(processSubscribe(response.data.data.subscription_id, response.data.data.applied_coupons))
            
        }else{
            enqueueSnackbar('Subscription Failed', {
                variant: 'error'
            })
        }
        
        dispatch(SubscriptionSlice.actions.setTrailLoading(false));
      } catch (error) {
        dispatch(SubscriptionSlice.actions.setTrailLoading(false));
        enqueueSnackbar('Subscription Failed', {
            variant: 'error'
        })
        dispatch(SubscriptionSlice.actions.hasError(error));
      }
    };

  }



  export function processSubscribe(id, plan) {

    return async () => {
      dispatch(SubscriptionSlice.actions.setSubscriptionLoading(true));
      try {
        const response = await axios.post(`/subscribe`,
          {'subscriptionId': id,
            "applied_coupons": plan
          });
        console.log('processCoupen', response)
        if(response.data.status === true){
            dispatch(SubscriptionSlice.actions.setSubscriptionResponse(response.data.data))
            dispatch(setSubscriptionDialog(false))
            dispatch(getSubscription())
            // dispatch(setSubscriptionPlan(plan))
            enqueueSnackbar(`Subscription Success`)
        }else{
            enqueueSnackbar('Subscription Failed', {
                variant: 'error'
            })
        }
        
        dispatch(SubscriptionSlice.actions.setSubscriptionLoading(false));
      } catch (error) {
        dispatch(SubscriptionSlice.actions.setSubscriptionLoading(false));
        enqueueSnackbar('Subscription Failed', {
            variant: 'error'
        })
        dispatch(SubscriptionSlice.actions.hasError(error));
      }
    };

  }

  
  export function getSubscription() {

    return async () => {
      dispatch(SubscriptionSlice.actions.setLoadingSubscriptionExisting(true));
      try {
        const response = await axios.get(`/subscription/me`);
        console.log('getSubscription', response)
        if(response.data.status === true){
            if(response.data.data.length === 0){
                dispatch(setSubscriptionDialog(true))
                dispatch(setSubscriptionPlan(null))
            }else{
                dispatch(SubscriptionSlice.actions.setSubscriptionData(response.data.data))
                dispatch(setSubscriptionPlan(response.data.data.name))
                dispatch(setSubscriptionDialog(false))
                // if(response.data.data.package === 'TRIAL'){
                //     dispatch(setSubscriptionDialog(true))
                // }else{
                //     dispatch(setSubscriptionDialog(false))
                // }
            }
        }
        dispatch(SubscriptionSlice.actions.setLoadingSubscriptionExisting(false));
      } catch (error) {
        dispatch(SubscriptionSlice.actions.setLoadingSubscriptionExisting(false));
        dispatch(SubscriptionSlice.actions.hasError(error));
      }
    };

  }
  
  
  
  
  